.privacy-policy-main {
    margin: 7em auto;
    background-color: white;
    width: 850px;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .08);
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid rgba(0, 0, 0, .125);
    border-radius: 1em !important;
    font-family: 'Montserrat' !important;
    font-style: normal !important;

    .header {
        display: flex;
        flex-direction: column;
        padding: 3em;
        gap: 1em;
        background-color: var(--bg-color);
        border-radius: 1em 1em 0 0;

        .title {
            color: white;
            font-size: 2em;
            font-weight: 700;
        }

        .content {
            color: white;
            font-size: 1em;
            font-weight: 500;
        }
    }

    .body {
        padding: 3em;
        display: flex;
        flex-direction: column;
        gap: .5em;
        border-radius: 0 0 1em 1em;

        .title {
            font-size: 1.5em;
            font-weight: 700;
        }

        .sub-title {
            margin-top: 1em;
            font-size: 1.1em;
            font-weight: 700;
        }

        .content {
            font-size: 1em;
            font-weight: 500;
            display: flex;
            flex-direction: column;
            gap: 1em;
        }

        .sub-content {
            margin-left: 1em;
            font-size: 1em;
            font-weight: 500;

            ul {
                li {
                    font-size: .9em;
                    font-weight: 400;
                    list-style: lower-alpha;
                    margin-top: .5em;
                }
            }
        }
    }

    a {
        letter-spacing: 0;
        color: #344767;
        text-decoration: none;
        margin-left: .5em;
    }
}

@media screen and (max-width: 900px) {
    .privacy-policy-main {
        width: 90vw !important;
    }

}