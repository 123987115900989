.facility-statistics {
    padding: 24px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
    
    .statistics-title {
        margin-bottom: 32px;
        border-bottom: 1px solid #f0f0f0;
        padding-bottom: 16px;
        
        h5 {
            font-size: 24px;
            font-weight: bold;
            color: #262626;
            margin: 0;
        }
    }

    .statistics-filter {
        display: flex;
        gap: 24px;
        margin-bottom: 32px;
        align-items: center;
        flex-wrap: wrap;

        .ant-select {
            &:first-child {
                min-width: 120px;
            }
            &:last-child {
                min-width: 300px;
            }
        }
    }

    .total-stats {
        background: #fafafa;
        padding: 16px 24px;
        border-radius: 6px;
        margin: 24px 0 32px;

        .stat-item {
            display: flex;
            align-items: center;
            gap: 8px;
            color: #595959;

            b {
                color: #262626;
                font-size: 16px;
            }
        }
    }

    .charts {
        margin-top: 32px;

        .chart-container {
            display: flex;
            width: 100%;
            justify-content: center;
            padding: 24px;
            background: #fff;
            border-radius: 8px;
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
            flex-direction: column;
            align-items: center;
        }

        .radar-amchart {
            width: 580px;
            height: 580px;
            position: relative;
            margin-bottom: 24px;

            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 100%);
                pointer-events: none;
                opacity: 0.1;
            }
        }

        .labels {
            display: flex;
            justify-content: center;
            gap: 32px;
            padding: 12px 24px;
            background: #fafafa;
            border-radius: 6px;
            width: fit-content;
            margin: 0 auto;

            &__item {
                display: flex;
                align-items: center;
                gap: 8px;

                &__color {
                    width: 16px;
                    height: 16px;
                    border-radius: 4px;
                    flex-shrink: 0;
                }

                &__text {
                    font-size: 14px;
                    color: #595959;
                    white-space: nowrap;
                }
            }
        }
    }
}

// Responsive styles
@media (max-width: 768px) {
    .facility-statistics {
        padding: 16px;

        .statistics-filter {
            flex-direction: column;
            align-items: stretch;
            
            .ant-select {
                width: 100% !important;
            }
        }

        .chart-container {
            padding: 16px;
        }

        .radar-amchart {
            width: 100% !important;
            height: auto !important;
            aspect-ratio: 1;
            margin-bottom: 16px;
        }

        .labels {
            padding: 12px 16px;
            flex-direction: column;
            align-items: flex-start;
            gap: 12px;
            width: 100%;
        }
    }
} 