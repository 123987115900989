@import '~antd/dist/antd.css';
@import "../../index.css";

.main-header {
    position: fixed !important;
    justify-content: space-around;
    width: 100%;
    background: white;
    z-index: 100;
    font-family: 'Montserrat' !important;
    font-style: normal !important;
    display: flex;
    align-items: center;
    padding: 10px 100px;
    box-shadow: 0 .3125rem .625rem 0 rgba(0, 0, 0, .08);

    .header-logo {
        .logo-text {
            color: var(--color-primary);
            font-size: clamp(1em, 2.5vw, 1.7em);
            font-weight: bold;
            cursor: pointer;
        }
    }

    .header-menu {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        border: none;
        margin-left: 100px;
        gap: 1em;

        .header-menu-items {
            display: flex;
        }

        .choose-type {
            .ant-menu-submenu-title {
                color: #696969;
            }
        }

        .ant-menu-item a:hover {
            color: var(--color-primary) !important;
            border: none;
        }
    }

    .header-menu.admin {
        // justify-content: center;
        // margin-left: 0;
    }

    .ant-menu-horizontal>.ant-menu-item a::before {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #858585
    }

    .ant-menu-horizontal>.ant-menu-item-selected a {
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        color: var(--color-primary) !important;
        transition: 0.3s all ease-in-out;
    }

    .ant-menu-horizontal>.ant-menu-item::after,
    .ant-menu-horizontal>.ant-menu-submenu::after {
        display: none !important;
    }

    .header-content-input {
        position: relative;
        display: flex;
        padding: 8.5px 10px;
        align-items: center;

        .search-input {
            min-width: none;
            width: 300px;
            letter-spacing: 0;
            line-height: 20px;
            white-space: nowrap;
            border-radius: 20px;
            font-size: 14px;
            line-height: 20px;
            font-weight: 500;
            display: flex;
            align-items: center;
            color: #696969;
        }

        .search-input:hover {
            border: 1px solid var(--color-primary) !important;
        }

        .search-input:focus {
            border: 1px solid var(--color-primary) !important;
            // border-color: var(--color-primary);
            box-shadow: 0 0 0 1px var(--color-primary);
            caret-color: var(--color-primary);
        }

        .icon-search {
            position: absolute;
            right: 15px;
            padding: 7px;
            pointer-events: none;
            height: 30px;
            width: 30px;
        }
    }

    .header-content-input.login {
        margin-right: 120px;
    }

    .header-button button {
        background: var(--color-primary);
        border-radius: 30px;
        color: #FFFFFF;
        border: none;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
    }


    .header-button button:hover {
        font-weight: 700;
    }

    .header-avatar-content {
        position: absolute;
        right: 50px;

        .header-avatar-right {
            display: flex;
            justify-content: center;
            align-items: center;

            .header-avatar-name {
                font-weight: 700;
                font-size: 14px;
                line-height: 20px;
                display: flex;
                align-items: center;
                text-align: right;
                color: #696969;
            }

            .header-avatar-position {
                font-weight: 400;
                font-size: 10px;
                line-height: 16px;
                display: flex;
                align-items: center;
                text-align: right;
                color: #C0C0C0;
                margin: 0px 10px;
            }
        }

        .anticon {
            margin-left: 5px;
            margin-right: 10px;
        }
    }

    .menubtn {
        background: var(--color-primary);
        border: none;

    }

    .menubtn:hover,
    .menubtn:focus {
        animation: pulse 1s;
        box-shadow: 0 0 0 2em transparent;
        background: var(--color-primary);
    }

}

.ant-drawer {
    .ant-drawer-header {
        .header-logo {
            .logo-text {
                color: var(--color-primary);
                font-size: 20px;
                font-weight: bold;
            }
        }

        .ant-drawer-close {
            top: 10px;
            right: 5px;
            width: 25px;
            height: 25px;
            position: absolute;
            -webkit-transition: -webkit-transform .25s, opacity .25s;
            -moz-transition: -moz-transform .25s, opacity .25s;
            transition: transform .25s, opacity .25s;
            opacity: .25;
        }

        .ant-drawer-close:hover {
            -webkit-transform: rotate(360deg);
            -moz-transform: rotate(360deg);
            transform: rotate(360deg);
            opacity: 1;
        }
    }

    .header-menu-drawer {
        display: flex;
        justify-content: center;
        text-align: center;
    }
}

/*# sourceMappingURL=core.css.map */
.ant-menu-horizontal {
    border: none !important;
}

.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu:hover,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-selected {
    color: var(--color-primary) !important;
}

.ant-menu-overflow-item:hover {
    color: var(--color-primary) !important;
}

.ant-menu-light.ant-menu-item:hover,
.ant-menu-light.ant-menu-item-active,
.ant-menu-light.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-light.ant-menu-submenu-active,
.ant-menu-light.ant-menu-submenu-title:hover {
    color: var(--color-primary) !important;
}

.ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-item-active,
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-light .ant-menu-submenu-active,
.ant-menu-light .ant-menu-submenu-title:hover {
    color: var(--color-primary) !important;
}

.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
    color: var(--color-primary) !important;
}

.ant-menu-item a:hover {
    color: var(--color-primary) !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: var(--bg-color-05) !important;
}

.ant-menu-vertical .ant-menu-submenu-selected,
.ant-menu-vertical-left .ant-menu-submenu-selected,
.ant-menu-vertical-right .ant-menu-submenu-selected {
    // background-color: #ffe3e9 !important;
    color: var(--color-primary) !important;
}

.ant-menu-submenu:hover>.ant-menu-submenu-title>.ant-menu-submenu-expand-icon,
.ant-menu-submenu:hover>.ant-menu-submenu-title>.ant-menu-submenu-arrow {
    color: var(--color-primary) !important;
}

.ant-menu-item:active,
.ant-menu-submenu-title:active {
    background-color: white !important;
}

.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-selected::after {
    border-bottom: 0px solid var(--color-primary) !important;
}

.-title-content {
    font-size: 1em;
    font-weight: 700;
    padding: 0 .5em;
    color: white;
    border-radius: 30px;
    background-color: var(--color-primary);
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    gap: .5em;
    // transition: all 0.3s ease-in-out;
}

.-title-content:hover {
    // scale: 1.1;
}

.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu {
    padding: 0 5px !important;
}

.ant-menu-item.ant-menu-item-active.ant-menu-item-only-child:hover {
    transition: all 0.3s ease-in-out;

    .ant-menu-title-content {
        border-bottom: 0px solid var(--color-primary) !important;
    }
}

.ant-menu-title-content {
    font-size: 1em;
}

.ant-dropdown-menu {
    width: 200px;
}

.user-statistics-modal{
    .ant-modal-header{
        display: none;
    }

    .user-statistics{
        display: flex;
        flex-direction: column;
        gap: 10px;

        .user-statistics-info{
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 10px;
        }

        .user-statistics-chart{
            .title-content-score{
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 10px;

                font-size: 1.5em;
                font-weight: 700;
            }
        }
    }    
}

@media (min-width: 1280px) {
    .menubtn {
        display: none;
    }

    .header-menu {
        right: 10px;
    }
}

@media (max-width: 1280px) {
    .main-header {
        justify-content: flex-start;

        .header-button {
            position: absolute;
            right: 70px;
        }

        .header-content-input {
            position: absolute;
            right: 260px;
        }

        .header-content-input.login {
            position: absolute;
            right: 120px;
        }

        .header-avatar {
            position: absolute;
            right: 70px;
        }
    }


    .header-menu.login {
        display: block;
        margin-left: 10px;
    }

    .ant-drawer-content-wrapper {
        width: 50% !important;
    }

    .menubtn.login {
        display: none;
    }

    .menubtn {
        display: none;
    }
}

@media (max-width: 1100px) {
    .header-menu {
        display: none !important;
    }

    .menubtn {
        display: block;
        position: absolute;
        right: 20px;
    }

    .main-header {
        padding: 10px 25px;
    }

    .header-menu.login {
        display: none;
    }

    .menubtn.login {
        display: block;
        position: absolute;
        right: 20px;
    }
}


@media (max-width: 800px) {
    .main-header {
        padding-left: 25px;
    }

    .header-menu {
        display: none;
    }

    .ant-drawer-content-wrapper {
        width: 75% !important;
    }

    .header-content-input {
        display: none !important;
    }

    .menubtn {
        position: absolute;
        right: 20px;
    }
}

@media (max-width: 500px) {
    .header-button {
        display: none !important;
    }

    .main-header {
        .header-avatar-content {
            .header-avatar-right {
                .header-avatar-position {
                    display: none;
                }
            }
        }
    }
}

@media (max-width: 300px) {

    .main-header {
        .header-avatar-content {
            .header-avatar-right {
                .header-avatar-name {
                    font-size: 12px;
                }
            }
        }
    }
}

.ant-dropdown-menu-submenu-expand-icon {
    top: 0px;
}