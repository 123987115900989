.year-management {
    background: white;
    padding: 5em 1em;
    position: relative;

    .usermanagement-title {
        user-select: none;
        font-size: 2em;
        font-weight: bold;
        text-align: center;
        transition: all 0.3s ease-in-out;
    }

    .usermanagement-title:hover {
        color: #FF6600;
    }

    .usermanagement-filter {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 1em;
        gap: 1em;

        .ant-input {
            width: 20vw;
            display: flex;
            align-items: center;
            color: #4E4E4E;
        }

        .ant-input:hover {
            outline: none;
            border: 1px solid #FF6600;
        }

        .ant-input:focus {
            outline: none;
            border: 1px solid #FF6600;
            box-shadow: 0 0 0 1px #FF9955;
            caret-color: #FF9955;
        }
    }

    .year-management__content {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1em;
        margin-top: 1em;
    }

    // Tạo ra labels có position absolute để đặt phải của chart
    .charts {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 30px;

        .chart {
            position: relative;
            width: 500px;
            height: 500px;
            margin: 20px 0 50px;

            .chart-polar {
                position: relative;
                z-index: 10;
                .logo-chart {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    background-color: white;
                    border-radius: 50%;
                }
            }

            .chart-dough {
                width: 580px;
                height: 580px;
                // z-index: 1;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        .labels {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 1em;

            .labels__item {
                display: flex;
                gap: 1em;
                align-items: center;

                .labels__item__color {
                    width: 1em;
                    height: 1em;
                    background-color: red;
                }

                .labels__item__text {
                    font-size: 1em;
                    font-weight: 700;
                }
            }

        }
    }
}

@media (max-width: 400px) {
    .year-management {
        background: white;
        padding: 4em 1em;
        position: relative;

        .usermanagement-title {
            user-select: none;
            font-size: 30px;
            font-weight: bold;
            text-align: center;
            transition: all 0.3s ease-in-out;
        }

        .usermanagement-title:hover {
            color: #FF6600;
        }

        .usermanagement-filter {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 1em;
            gap: 1em;

            .ant-input {
                width: 20vw;
                display: flex;
                align-items: center;
                color: #4E4E4E;
            }

            .ant-input:hover {
                outline: none;
                border: 1px solid #FF6600;
            }

            .ant-input:focus {
                outline: none;
                border: 1px solid #FF6600;
                box-shadow: 0 0 0 1px #FF9955;
                caret-color: #FF9955;
            }
        }

        .year-management__content {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 1em;
            margin-top: 1em;
        }

        // Tạo ra labels có position absolute để đặt phải của chart
        .charts {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin-bottom: 30px;

            .chart {
                position: relative;
                width: 250px;
                height: 250px;

                .chart-polar {
                    position: relative;
                    z-index: 10;

                    .logo-chart {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        z-index: 0;
                        background-color: white;
                        border-radius: 50%;
                        img{
                            width: 30px;
                        }
                    }
                }

                .chart-dough {
                    width: 270px;
                    height: 270px;
                    // z-index: 1;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }

            .labels {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 10px;

                .labels__item {
                    display: flex;
                    gap: 5px;
                    align-items: center;

                    .labels__item__color {
                        width: 10px;
                        height: 10px;
                        background-color: red;
                    }

                    .labels__item__text {
                        font-size: 8px;
                        font-weight: 700;
                    }
                }

            }
        }
    }
}

.apexcharts-toolbar {
    // display: none !important;
}

.apexcharts-title-text {
    font-family: 'Montserrat' !important;
}

.apexcharts-text {
    font-family: 'Montserrat' !important;
}