@import '~antd/dist/antd.css';
@import "../../index.css";

.main-home {
    font-family: 'Montserrat';
    font-style: normal;
    user-select: none;
    background: white;

    .intro-part {
        display: flex;
        justify-content: space-around;
        // border: solid;
        z-index: 2;
        position: relative;
        height: 500px;

        .text-of-intro {
            margin-top: 100px;
            margin-left: 100px;
            width: 50%;

            .title {
                font-weight: 700;
                font-size: 30px;
                color: #1E1E1E;
            }

            .detail {
                margin-top: 10px;
                margin-bottom: 27px;
                font-weight: 500;
                font-size: 14px;
                color: #858585;

            }

            .home-button {
                width: 100px;
            }

            .home-button button {
                background: var(--color-primary);
                border-radius: 30px;
                color: #FFFFFF;
                border: none;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
            }


            .home-button button:hover {
                font-weight: 700;
            }
        }

        .image-of-intro {
            display: flex;
            justify-content: end;
            align-items: center;
            margin-right: 20px;
            width: 50%;
            // margin-bottom: 100px;
            padding-bottom: 100px;

            img {
                margin-top: 200px;
                width: 100%;
                // padding-bottom: 50px;
                // margin-bottom: 50px;
            }
        }
    }

    .tool-of-web {
        margin: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: #FFF6EE;
        padding-bottom: 30px;
        border-radius: 30px;
        margin-bottom: -10px;

        .title {
            margin-top: 30px;
            font-weight: 700;
            font-size: 25px;
            text-align: center;
            color: #1E1E1E;
        }

        .subtitle {
            font-weight: 500;
            font-size: 12px;
            line-height: 24px;
            text-align: center;
            color: #858585;
            padding: 0px 150px;
            margin-bottom: 20px;
        }

        .lst-tool {
            display: flex;
            justify-content: space-around;
            align-items: center;
            width: 100%;

            .tool {
                width: calc(361.33px + 50px);
                height: calc(380px + 50px);
                background-repeat: no-repeat;
                background-size: cover;
                cursor: pointer;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .tool-title {
                    font-weight: 700;
                    font-size: 40px;
                    text-align: center;
                    color: #FFFFFF;
                    // padding-top: 150px;
                }

                .tool-subtitle {
                    font-weight: 500;
                    font-size: 20px;
                    text-align: center;
                    color: #FFFFFF;
                    padding: 0px 30px;
                    margin-bottom: 10px;
                }

                .btn-tool {
                    padding: 1em;
                    background: none;
                    border: 2px solid #FFFFFF;
                    border-radius: 30px;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 20px;
                    display: flex;
                    align-items: center;
                    text-align: right;
                    color: #FFFFFF;
                }
            }

            .tool:nth-child(1) {
                background-image: url("../../images/image-tool-1.png");
            }

            .tool:nth-child(2) {
                background-image: url("../../images/image-tool-2.png");
            }

            .tool:nth-child(3) {
                background-image: url("../../images/image-tool-3.png");
            }
        }
    }

    .right-of-user {
        z-index: 1;
        position: relative;
        margin-top: 40px;

        .title {
            text-align: center;
            font-weight: 640;
            font-size: 30px;
            padding-bottom: 40px;
        }

        .right-lst {
            display: flex;
            margin: 10px 20px;
            justify-content: center;
            margin-bottom: 50px;

            .lst-item {
                width: 200px;
                text-align: center;
                margin: 10px;
                border-radius: 15px;
                padding: 20px;
                position: relative;
                cursor: pointer;
                user-select: none;

                .lst-item-image {
                    position: absolute;
                    top: -50px;
                    left: -20px;
                    height: 100px;
                    width: 100px;
                }

                .lst-item-title {
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 32px;
                    text-align: center;
                    color: #FFFFFF;
                    margin-top: 30px;
                }

                .lst-item-content {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 32px;
                    text-align: center;
                    color: #FFFFFF;
                }
            }

            .lst-item:nth-child(1) {
                // background-color: #a027ff;
                // background-color: #59009c;
                background-image: url("../../images/right-of-user-1.png");
                background-repeat: no-repeat;
                background-size: cover;

                .lst-item-image {
                    top: -60px;
                    left: -20px;
                    width: 109px;
                    height: 100px;
                }
            }

            .lst-item:nth-child(2) {
                background-color: #20c3b5;

                .lst-item-image {
                    top: -55px;
                    left: -45px;
                    width: 156px;
                    height: 100px;
                }
            }

            .lst-item:nth-child(3) {
                background-image: url("../../images/right-of-user-3.png");
                background-repeat: no-repeat;
                background-size: cover;

                .lst-item-image {
                    top: -53px;
                    left: -15px;
                    width: 68px;
                    height: 100px;
                }
            }

            .lst-item:nth-child(4) {
                background-image: url("../../images/right-of-user-4.png");
                background-repeat: no-repeat;
                background-size: cover;

                .lst-item-image {
                    top: -42px;
                    left: -15px;
                    width: 80px;
                    height: 80px;
                }
            }

            .lst-item:nth-child(5) {
                background-image: url("../../images/right-of-user-5.png");
                background-repeat: no-repeat;
                background-size: cover;

                .lst-item-image {
                    width: 131px;
                    height: 72px;
                    top: -40px;
                    left: -20px;
                }
            }
        }
    }

    .how-to-use {
        display: flex;
        z-index: 2;
        position: relative;
        justify-content: space-around;
        border-radius: 0 192px 192px 0;
        padding: 40px;
        // margin-bottom: -300px;

        .tutorial-image {
            display: flex;
            justify-content: start;
            align-items: center;
            margin-top: -100px;
            margin-right: -100px;
            width: 50%;

            img {
                height: 700px;
                width: 700px;
                margin-left: -50px;
            }
        }

        .content {
            margin-top: 30px;
            width: 55%;

            .title {
                font-weight: 700;
                font-size: 30px;
                color: #1E1E1E;
            }

            .subtitle {
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
                color: #858585;
            }

            .step-lst {
                .row-item {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .item-lst {
                        position: relative;
                        background: radial-gradient(53.88% 53.88% at 50% 50%, #FFECE5 0%, rgba(255, 236, 229, 0) 100%);
                        // filter: drop-shadow(1px 10px 100px rgba(255, 153, 85, 0.15));
                        box-shadow: 1px 10px 100px rgba(255, 153, 85, 0.15);
                        border-radius: 30px;
                        width: 350px;
                        height: 200px;
                        margin: 30px;
                        padding: 50px;
                        margin-left: 0px;
                        cursor: pointer;

                        .item-title {
                            font-weight: 700;
                            font-size: 14px;
                            line-height: 20px;
                            display: flex;
                            align-items: center;
                            color: var(--color-primary);
                        }

                        .item-sub-title {
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 24px;
                            display: flex;
                            align-items: center;
                            color: #858585;
                        }

                        .item-img {
                            position: absolute;
                            top: -25px;
                            right: 0;
                        }
                    }
                }
            }
        }
    }

    .tutorial {
        .tutorial-title {
            font-weight: 700;
            font-size: 30px;
            line-height: 56px;
            color: #1E1E1E;
            text-align: center;
        }

        .tutorial-sub-title {
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            text-align: center;
            color: #858585;
            padding: 0px 150px;
        }

        .first-tutorial {
            display: flex;
            align-items: center;
            justify-content: center;

            .f-t-left {
                margin: 0px 75px;

                .f-t-l-item {
                    display: flex;
                    align-items: center;
                    position: relative;
                    background: radial-gradient(53.88% 53.88% at 50% 50%, #FFECE5 0%, rgba(255, 236, 229, 0) 100%);
                    box-shadow: 1px 10px 100px rgb(255 153 85 / 15%);
                    border-radius: 30px;
                    width: 500px;
                    height: 100px;
                    padding: 10px;
                    margin-left: 50px;
                    margin-bottom: 20px;
                    cursor: pointer;

                    .f-t-l-t-icon {
                        position: absolute;
                        top: -20px;
                        left: -33px;
                        height: 80px;
                        width: 80px;
                    }

                    .f-t-l-t-content {
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 32px;
                        display: flex;
                        align-items: center;
                        color: #858585;
                        margin-left: 40px;
                    }
                }

                .f-t-l-item.long-item {
                    height: 300px;
                }
            }

            .f-t-right {
                img {
                    cursor: pointer;
                    width: 100%;
                }
            }

        }
    }

    .map-user {
        .map-title {
            font-weight: 700;
            font-size: 30px;
            text-align: center;
            color: #1E1E1E;
        }

        .map-sub-title {
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            text-align: center;
            color: #858585;
        }

        .gmap_iframe {
            height: 600px;
            width: 100%;
            border-radius: 40px;
            // margin: 50px;
            padding: 50px;
        }
    }

    .our-partner {
        // margin-top: -200px;
        z-index: 2;
        position: relative;
        border-radius: 0 192px 0 0;
        padding-bottom: 80px;

        .title {
            padding-top: 50px;
            text-align: center;
            font-weight: 700;
            font-size: 30px;
            line-height: 56px;
            color: #1E1E1E;
        }

        .sub-title {
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            text-align: center;
            color: #858585;
        }

        .partner-lst {
            max-width: 1200px;
            padding: 0px 50px;
            margin: 50px auto;
            display: grid;
            gap: 2rem;
            grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));

            .item {
                background-color: #EEF2F6;
                color: #858585;
                padding: 1rem;
                height: 4rem;
            }
        }
    }

    .parameter-lst {
        display: flex;
        background: #FFF6EE;
        border-radius: 30px;
        justify-content: space-between;
        align-items: center;
        gap: 1em;
        padding: 40px;
        margin: 40px 70px;
        // width: 1300px;
        // margin: 0px auto;

        .parameter {
            display: flex;
            align-items: center;
            gap: 1em;

            .parameter-image {
                height: 6em;
                width: 6em;
                cursor: pointer;
            }

            .parameter-content {
                font-weight: 500;
                font-size: 1em;
                color: #4E4E4E;

                .number-user {
                    font-weight: 700;
                    font-size: 2em;
                    color: #4E4E4E;
                }
            }
        }
    }
}

.ant-notification {
    .ant-notification-notice {
        border-radius: 10px;

        .ant-notification-notice-content {}
    }
}

@media (min-width: 1300px) {
    .main-home {
        .intro-part {
            .image-of-intro {
                img {
                    margin-left: -100px !important;
                }
            }
        }
    }
}

@media (max-width: 1300px) {
    .main-home {
        .intro-part {
            .image-of-intro {
                img {
                    margin-left: -275px !important;
                }
            }
        }

        .tool-of-web {
            .lst-tool {
                .tool {
                    width: calc(361.33px - 50px);
                    height: calc(380px - 50px);

                    .tool-title {
                        font-size: 30px;
                        padding-top: 150px;
                    }

                    .tool-subtitle {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

@media (max-width: 1100px) {
    .main-home {
        .intro-part {

            .text-of-intro {
                .title {
                    font-size: 25px;
                }

                .detail {
                    font-size: 13px;
                }
            }

            .image-of-intro {
                img {
                    margin-top: 100px;
                    margin-left: -400px !important;
                }
            }
        }

        .tool-of-web {
            .subtitle {
                padding: 0px 10px;
            }

            .lst-tool {
                .tool {
                    width: calc(261.33px - 50px);
                    height: calc(280px - 50px);

                    .tool-title {
                        font-size: 18px;
                        padding-top: 50px;
                    }
                }
            }
        }

        .how-to-use {
            .tutorial-image {
                margin-top: -125px;
                margin-right: -0px;

                img {
                    margin-right: -300px;
                    height: 600px;
                    width: 600px;
                }
            }

            .content {
                .step-lst {
                    .row-item {
                        .item-lst {
                            height: 300px;
                        }
                    }
                }
            }
        }

        .tutorial {

            .first-tutorial {
                .f-t-right {
                    margin-left: -100px;
                }

                .f-t-left {
                    .f-t-l-item {}
                }
            }

            .first-tutorial.second-tutorial {
                .f-t-right {
                    margin-top: -50px;
                    margin-right: -200px;
                    margin-left: 0px;
                }

                .f-t-left {
                    .f-t-l-item {}
                }
            }
        }

        .right-of-user {
            .right-lst {
                .lst-item {
                    .lst-item-title {
                        font-size: 14px;
                    }

                    .lst-item-content {
                        font-size: 12px;
                    }
                }
            }
        }
    }
}

@media(max-width: 1000px) {
    .main-home {
        .parameter-lst {
            .parameter {
                .parameter-image {
                    height: 4em;
                    width: 4em;
                }

                .parameter-content {
                    font-size: .8em;

                    .number-user {
                        font-size: 1.5em;
                    }
                }
            }
        }
    }
}

@media (max-width: 900px) {
    .main-home {
        .intro-part {

            .text-of-intro {
                width: 100%;
                margin: 100px 25px 50px 25px;
            }

            .image-of-intro {
                display: none !important;
            }
        }

        .tool-of-web {
            margin: 20px;
        }

        .how-to-use {
            .tutorial-image {
                display: none !important;
            }

            .content {
                width: 100%;

                .step-lst {
                    .row-item {
                        .item-lst {
                            height: 200px;
                        }
                    }
                }
            }
        }


        .right-of-user {
            .right-lst {
                .lst-item {
                    padding: 10px;

                    .lst-item-title {
                        font-size: 12px;
                    }

                    .lst-item-content {
                        font-size: 11px;
                    }
                }

                .lst-item:nth-child(1) {
                    background-color: #a027ff;

                    .lst-item-image {
                        top: -35px;
                        left: -20px;
                        width: calc(100px - 20px);
                        height: calc(100px - 20px);
                    }
                }

                .lst-item:nth-child(2) {
                    background-color: #20c3b5;

                    .lst-item-image {
                        top: -30px;
                        left: -45px;
                        width: calc(156px - 20px);
                        height: calc(100px - 20px);
                    }
                }

                .lst-item:nth-child(3) {
                    background-color: #ff6e83;

                    .lst-item-image {
                        top: -38px;
                        left: -10px;
                        width: calc(68px - 10px);
                        height: calc(100px - 10px);
                    }
                }

                .lst-item:nth-child(4) {
                    background-color: #446cfa;

                    .lst-item-image {
                        top: calc(-42px + 20px);
                        left: -15px;
                        width: calc(80px - 20px);
                        height: calc(80px - 20px);
                    }
                }

                .lst-item:nth-child(5) {
                    background-color: #fda932;

                    .lst-item-image {
                        top: calc(-40px + 20px);
                        left: -20px;
                        width: calc(131px - 20px);
                        height: calc(72px - 20px);
                    }
                }
            }
        }

        .tutorial {
            .tutorial-sub-title {
                padding: 0px 50px;
            }

            .first-tutorial {
                .f-t-right {
                    // width: 100%;
                    display: none;
                }

                .f-t-left {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    .f-t-l-item {
                        margin-left: 0px;
                        width: 100%;
                    }
                }
            }
        }
    }
}

@media(max-width: 800px) {
    .main-home {
        .parameter-lst {
            padding: 20px;

            .parameter {
                .parameter-image {
                    height: 3em;
                    width: 3em;
                }

                .parameter-content {
                    font-size: .5em;

                    .number-user {
                        font-size: 1.2em;
                    }
                }
            }
        }
    }
}

@media (max-width: 700px) {
    .main-home {
        .tool-of-web {
            .lst-tool {
                .tool {
                    width: calc(269.33px - 100px);
                    height: calc(280px - 100px);

                    .tool-title {
                        font-size: 14px;
                        padding-top: 20px;
                    }

                    .tool-subtitle {
                        font-size: 12px;
                        padding: 0px 10px;
                    }

                    .btn-tool {
                        font-size: 10px;
                    }
                }
            }
        }

        .how-to-use {
            .content {
                width: 100%;

                .step-lst {
                    .row-item {
                        .item-lst {
                            height: 300px;
                            padding: 30px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 600px) {
    .main-home {
        .intro-part {

            .text-of-intro {
                margin: 100px 25px 50px 25px;

                .title {
                    font-size: 20px;
                }

                .detail {
                    font-size: 12px;
                }

                .button-start {
                    font-size: 12px;
                }
            }

            .image-of-intro {
                display: none !important;
            }
        }

        .tool-of-web {
            .lst-tool {
                flex-direction: column;

                .tool {
                    width: 90%;
                    height: 100px;
                    margin-bottom: 20px;
                    padding: 0px 20px;

                    .tool-title {
                        padding-top: 10px;
                    }

                    .tool-subtitle {}

                    .btn-tool {}
                }

                .tool:nth-child(1) {
                    background: linear-gradient(138.87deg, #FF5A80 0%, #FFC19E 99.02%);
                    border-radius: 40px;
                }

                .tool:nth-child(2) {
                    background: linear-gradient(125.34deg, #46D6A1 0%, #9FDE69 100%);
                    border-radius: 40px;
                }

                .tool:nth-child(3) {
                    background: linear-gradient(135deg, #6C7DEF 0%, #C981F7 100%);
                    border-radius: 40px;
                }
            }
        }

        .how-to-use {
            .content {
                .title {
                    font-size: 20px;
                }

                .subtitle {
                    font-size: 14px;
                }

                .step-lst {
                    .lst-item {
                        .text-item {
                            font-size: 12px;
                        }
                    }
                }
            }
        }

        .right-of-user {
            z-index: 3;

            .right-lst {
                flex-direction: column;

                .lst-item {
                    width: 100%;
                    margin: 10px 0px;

                    .lst-item-title {
                        font-size: 12px;
                        margin-top: 0 !important;
                    }

                    .lst-item-content {
                        font-size: 11px;
                    }

                    .lst-item-image {
                        display: none;
                    }
                }
            }
        }

        .tutorial {
            .tutorial-title {
                font-size: 20px;
            }

            .tutorial-sub-title {
                font-size: 12px;
            }

            .first-tutorial {
                .f-t-left {
                    .f-t-l-item {
                        .f-t-l-t-content {
                            font-size: 12px;
                        }
                    }
                }
            }
        }

        .our-partner {
            .title {
                font-size: 25px;
            }

        }

        .parameter-lst {
            flex-direction: column;
            align-items: baseline;
            margin: 40px;

            .parameter {
                .parameter-image {
                    height: 5em;
                    width: 5em;
                }

                .parameter-content {
                    font-size: 1em;

                    .number-user {
                        font-size: 2em;
                    }
                }
            }
        }
    }
}

@media (max-width: 500px) {
    .main-home {
        .tool-of-web {
            .title {
                font-size: 20px;
            }

            .subtitle {
                font-size: 12px;
            }

            .lst-tool {
                .tool {
                    .tool-title {
                        font-size: 12px;
                    }

                    .tool-subtitle {
                        font-size: 10px;
                        margin-bottom: 5px;
                    }

                    .btn-tool {
                        font-size: 8px;
                    }
                }

            }
        }

        .how-to-use {
            .content {
                width: 100%;

                .step-lst {
                    .row-item {
                        display: flex;
                        flex-direction: column;

                        .item-lst {
                            height: 150px;
                            padding-left: 50px;
                            margin: 10px;

                            .item-title {
                                font-size: 12px;
                            }

                            .item-sub-title {
                                font-size: 10px;
                            }

                            .item-img {
                                display: none;

                            }
                        }
                    }
                }
            }
        }

        .tutorial {
            .tutorial-title {
                font-size: 20px;
            }

            .tutorial-sub-title {
                font-size: 12px;
            }

            .first-tutorial {
                .f-t-left {
                    margin: 0px 20px;

                    .f-t-l-item {
                        padding: 0px;
                        margin: 5px;

                        .f-t-l-t-icon {
                            display: none;
                        }

                        .f-t-l-t-content {
                            font-size: 10px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 400px) {
    .main-home {
        .intro-part {
            height: 550px;

            .text-of-intro {
                margin: 100px 25px 50px 25px;

                .title {
                    // font-size: 15px;
                }

                .detail {
                    // font-size: 10px;
                }

                .button-start {
                    // font-size: 12px;
                }
            }

        }

        .tool-of-web {
            .lst-tool {
                .tool {
                    height: 120px;
                }
            }
        }

        .how-to-use {
            .content {
                .step-lst {
                    .row-item {
                        .item-lst {
                            width: 300px;

                            .item-title {
                                font-size: 14px;
                            }

                            .item-sub-title {
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
        }

        .tutorial {
            .first-tutorial {
                .f-t-left {
                    .f-t-l-item {
                        .f-t-l-t-content {
                            font-size: 12px;
                            margin-left: 20px;
                            // margin-right: 10px;
                        }
                    }

                    .f-t-l-item.long-item {
                        height: 350px;
                    }
                }
            }
        }

        .right-of-user {
            z-index: 3;

            .right-lst {
                flex-direction: column;

                .lst-item {
                    width: 100%;

                    .lst-item-title {
                        font-size: 12px;
                        margin-top: 0 !important;
                    }

                    .lst-item-content {
                        font-size: 11px;
                    }

                    .lst-item-image {
                        display: none;
                    }
                }
            }
        }

        .our-partner {
            .title {
                font-size: 20px;
            }

            .partner-lst {
                grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
            }
        }

        .parameter-lst {
            flex-direction: column;
            align-items: baseline;
            margin: 40px;

            .parameter {
                .parameter-image {
                    height: 4em;
                    width: 4em;
                }

                .parameter-content {
                    font-size: .8em;

                    .number-user {
                        font-size: 1.5em;
                    }
                }
            }
        }
    }

    .ant-modal {
        top: 50px !important;

    }

    .ant-modal .ant-modal-content .ant-modal-close-x {
        display: block !important;
    }
}

.ant-modal {
    .ant-modal-content {
        border-radius: 10px;
        box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);

        .ant-modal-close-x {
            display: none;
        }

        .ant-modal-header {
            border-radius: 10px 10px 0px 0px;
            // background-color: var(--color-primary);
            border-bottom: none;
            // padding: 20px 30px;

            .ant-modal-title {
                font-weight: 700;
                // font-size: 30px;
                // line-height: 56px;
                color: #FFFFFF;
            }
        }
    }

    .main-modal {
        .detail {
            margin-top: 10px;
            margin-bottom: 20px;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: #858585;

            li {
                font-size: 12px;
            }
        }
    }
}

.ant-modal.U-INNOVATE {
    .ant-modal-content {
        .ant-modal-header {
            background-color: #FF5A80;
        }
    }
}

.ant-modal.U-IMPACT {
    .ant-modal-content {
        .ant-modal-header {
            background-color: #92dd71;
        }
    }
}

.ant-modal.P-INNOVATE {
    .ant-modal-content {
        .ant-modal-header {
            background-color: #6C7DEF;
        }
    }
}