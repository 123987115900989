.usermanagement-main {
    background-color: white;
    padding: 5em 1em;

    .usermanagement-title {
        user-select: none;
        font-size: 2em;
        font-weight: bold;
        text-align: center;
        transition: all 0.3s ease-in-out;
    }

    .usermanagement-title:hover {
        color: #FF6600;
    }

    .usermanagement-filter {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 1em;
        gap: 1em;

        .ant-input {
            width: 20vw;
            display: flex;
            align-items: center;
            color: #4E4E4E;
        }

        .ant-input:hover {
            outline: none;
            border: 1px solid #FF6600;
        }

        .ant-input:focus {
            outline: none;
            border: 1px solid #FF6600;
            box-shadow: 0 0 0 1px #FF9955;
            caret-color: #FF9955;
        }
    }

    .usermanagement-table {
        .table-content {
            margin: 1em 9vw;

            .ant-table-wrapper {
                border-radius: 6px;

                .ant-table-thead {
                    tr {
                        th {
                            font-weight: 700;
                            text-align: center;
                            justify-content: center;
                        }

                        .ant-table-cell:nth-child(3) {
                            width: 15em !important;
                        }

                    }
                }

                .ant-table-tbody {
                    .ant-table-cell {
                        text-align: center;
                        justify-content: center;

                        .ant-space-item {
                            .warning {
                                color: #FF5A80;
                            }
                        }
                    }
                }
            }

            .even-row {
                background-color: rgba(255, 102, 0, 0.063)
            }

            .odd-row {
                background-color: #ffffff;
            }

            th {
                background-color: #ff66006e;
            }


        }

        .ant-table-tbody>tr>td {
            justify-content: center;
            text-align: center;
        }
    }

    .button-excel {
        margin-top: 20px;
        display: flex;
        justify-content: center;
        padding-right: 20px;

        .ant-btn {
            border-radius: 6px;
            border: none;
            background-color: #FF6600;
            color: white;
            font-weight: 700;
            font-size: 1em;
            cursor: pointer;
            transition: all 0.3s ease-in-out;
        }

        .ant-btn:disabled {
            background-color: #FF9955;
            cursor: not-allowed;
        }
    }

}

.usermanagement-detail-modal {
    top: 5%;

    .ant-modal-title {
        color: #4E4E4E !important;
    }

    .usermanagement-detail-footer {
        display: flex;
        justify-content: end;
        align-items: center;
        gap: 1em;

        .usermanagement-detail-footer-btn {
            border-radius: 6px;
            border: none;
            background-color: #FF6600;
            color: white;
            font-weight: 700;
            font-size: 1em;
            cursor: pointer;
            transition: all 0.3s ease-in-out;
        }

        .usermanagement-detail-footer-btn:hover {
            background-color: #FF9955;
        }
    }

    .ant-modal-body {
        padding-top: 0;

        .usermanagement-detail {
            .usermanagement-detail-title {
                text-align: center;
                font-size: 1.5em;
                font-weight: 700;
                margin-top: .5em;
                // margin-bottom: 1em;
            }

            .usermanagement-detail-content {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;

            }

            .table-content {
                margin: 0;

                .ant-table-wrapper {
                    border-radius: 6px;

                    .ant-table-thead {
                        tr {
                            th {
                                font-weight: 700;
                                text-align: center;
                                justify-content: center;
                            }

                            .ant-table-cell:nth-child(3) {
                                width: 15em !important;
                            }

                        }
                    }

                    .ant-table-tbody {
                        .ant-table-cell {
                            text-align: center;

                            .ant-space-item {
                                .warning {
                                    color: #FF5A80;
                                }
                            }
                        }
                    }
                }

                .even-row {
                    background-color: rgba(255, 102, 0, 0.063)
                }

                .odd-row {
                    background-color: #ffffff;
                }

                th {
                    background-color: #ff66006e;
                }


            }


        }
    }
}

.ant-pagination {
    display: flex;
    align-items: center;
    justify-content: center !important;

    .ant-pagination-item {
        border-radius: 10px;
    }

    .ant-pagination-item-link {
        border-radius: 10px !important;
    }

    .ant-pagination-item-active:focus-visible,
    .ant-pagination-item-active:hover,
    .ant-pagination-item:focus-visible,
    .ant-pagination-item:hover {
        // border-color: var(--color-primary);
        // box-shadow: 0 0 0 1px var(--color-primary);
        background-color: var(--color-primary) !important;

        a {
            color: white !important;
        }
    }

    .ant-pagination-prev,
    .ant-pagination-next {
        .ant-pagination-item-link:hover {
            border-color: var(--color-primary);
            color: var(--color-primary);
        }
    }

    .anticon svg {
        margin-bottom: 6px;
    }
}

.ant-table-thead>tr>th {
    text-align: center;
}

@media (max-width: 400px) {
    .usermanagement-main {
        background-color: white;
        padding: 4em 10px;

        .usermanagement-title {
            user-select: none;
            font-size: 20px;
            font-weight: bold;
            text-align: center;
            transition: all 0.3s ease-in-out;
        }

        .usermanagement-title:hover {
            color: #FF6600;
        }

        .usermanagement-filter {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 1em;
            gap: 1em;

            .ant-input {
                width: 40vw;
                display: flex;
                align-items: center;
                color: #4E4E4E;
            }
            .ant-select{
                width: 40vw;
                display: flex;
                align-items: center;
                color: #4E4E4E;
            }

            .ant-input:hover {
                outline: none;
                border: 1px solid #FF6600;
            }

            .ant-input:focus {
                outline: none;
                border: 1px solid #FF6600;
                box-shadow: 0 0 0 1px #FF9955;
                caret-color: #FF9955;
            }
        }

        .usermanagement-table {
            .table-content {
                margin: 1em 0;

                .ant-table-wrapper {
                    border-radius: 6px;

                    .ant-table-thead {
                        tr {
                            th {
                                font-weight: 700;
                                text-align: center;
                                justify-content: center;
                            }

                            .ant-table-cell:nth-child(3) {
                                width: 15em !important;
                            }

                        }
                    }

                    .ant-table-tbody {
                        .ant-table-cell {
                            text-align: center;
                            justify-content: center;

                            .ant-space-item {
                                .warning {
                                    color: #FF5A80;
                                }
                            }
                        }
                    }
                }

                .even-row {
                    background-color: rgba(255, 102, 0, 0.063)
                }

                .odd-row {
                    background-color: #ffffff;
                }

                th {
                    background-color: #ff66006e;
                    padding: 5px;
                }


            }

            .ant-table-tbody>tr>td {
                justify-content: center;
                text-align: center;
                padding: 5px;
            }
        }

        .button-excel {
            display: flex;
            justify-content: center;
            align-items: center;

            .ant-btn {
                border-radius: 6px;
                border: none;
                background-color: #FF9955;
                color: white;
                font-weight: 700;
                font-size: 1em;
                cursor: pointer;
                transition: all 0.3s ease-in-out;
            }

            .ant-btn:hover {
                background-color: #FF6600;
                scale: 1.1;
            }
        }

    }

    .usermanagement-detail-modal {
        top: 5%;

        .ant-modal-title {
            color: #4E4E4E !important;
        }

        .usermanagement-detail-footer {
            display: flex;
            justify-content: end;
            align-items: center;
            gap: 1em;

            .usermanagement-detail-footer-btn {
                border-radius: 6px;
                border: none;
                background-color: #FF6600;
                color: white;
                font-weight: 700;
                font-size: 1em;
                cursor: pointer;
                transition: all 0.3s ease-in-out;
            }

            .usermanagement-detail-footer-btn:hover {
                background-color: #FF9955;
            }
        }

        .ant-modal-body {
            padding-top: 0;

            .usermanagement-detail {
                .usermanagement-detail-title {
                    text-align: center;
                    font-size: 20px;
                    font-weight: 700;
                    margin: 1em;
                }

                .usermanagement-detail-content {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;

                }

                .table-content {
                    margin: 0;

                    .ant-table-wrapper {
                        border-radius: 6px;

                        .ant-table-thead {
                            tr {
                                th {
                                    font-weight: 700;
                                    text-align: center;
                                    justify-content: center;
                                }

                                .ant-table-cell:nth-child(3) {
                                    width: 15em !important;
                                }

                            }
                        }

                        .ant-table-tbody {
                            .ant-table-cell {
                                text-align: center;

                                .ant-space-item {
                                    .warning {
                                        color: #FF5A80;
                                    }
                                }
                            }
                        }
                    }

                    .even-row {
                        background-color: rgba(255, 102, 0, 0.063)
                    }

                    .odd-row {
                        background-color: #ffffff;
                    }

                    th {
                        background-color: #ff66006e;
                        padding: .5em;
                    }


                }


            }

            .ant-table-cell{
                padding: 5px;
            }
        }
    }

    .ant-pagination {
        display: flex;
        align-items: center;
        justify-content: center !important;

        .ant-pagination-item {
            border-radius: 10px;
        }

        .ant-pagination-item-link {
            border-radius: 10px !important;
        }

        .ant-pagination-item-active:focus-visible,
        .ant-pagination-item-active:hover,
        .ant-pagination-item:focus-visible,
        .ant-pagination-item:hover {
            // border-color: var(--color-primary);
            // box-shadow: 0 0 0 1px var(--color-primary);
            background-color: var(--color-primary) !important;

            a {
                color: white !important;
            }
        }

        .ant-pagination-prev,
        .ant-pagination-next {
            .ant-pagination-item-link:hover {
                border-color: var(--color-primary);
                color: var(--color-primary);
            }
        }

        .anticon svg {
            margin-bottom: 6px;
        }
    }

    .ant-table-thead>tr>th {
        text-align: center;
    }
}