body[data-theme='light'] {
  --color-primary: #FF6600;
  --bg-color: rgba(255, 102, 0, 0.8);
  --bg-color-05: rgba(255, 102, 0, 0.5);
}

body[data-theme='UINNOVATE'] {
  --color-primary: #FF5A80;
  --bg-color: rgba(255, 90, 128, 0.8);
  --bg-color-05: rgba(255, 90, 128, 0.5);
  --bg-note: #FEF9F5;

}

body[data-theme='UINNOVATE_SHORT'] {
  --color-primary: #FF5A80;
  --bg-color: rgba(255, 90, 128, 0.8);
  --bg-color-05: rgba(255, 90, 128, 0.5);
  --bg-note: #FEF9F5;
}

body[data-theme='UIMPACT'] {
  --color-primary: #46D6A1;
  --bg-color: rgba(70, 214, 161, 0.8);
  --bg-color-05: rgba(70, 214, 161, 0.5);
  --bg-note: #F1FFEF;
}

body[data-theme='PINNOVATE'] {
  --color-primary: #6C7DEF;
  --bg-color: rgba(108, 125, 239, 0.8);
  --bg-color-05: rgba(108, 125, 239, 0.086);
  --bg-note: #FAF5FE;
}