.footer-main {
    padding: 50px;
    display: flex;
    background-color: #FEF9F5;
    flex-direction: column;

    // .separation-line {
    //     margin-top: -25px;
    //     margin-bottom: 30px;
    //     align-items: center;
    // }

    .content {
        display: flex;
        justify-content: center;
        text-align: center;

        .content-left {
            display: flex;
            justify-content: center;
            text-align: center;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #858585;

            .link-to-page {
                margin: 20px 50px 20px 0;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: #858585;
            }

            .link-to-page:last-child {
                margin-right: 0px;
            }

            .link-to-page:hover {
                color: #454545;
            }

            .text-bold {
                font-weight: 700;
                font-size: 14px;
                line-height: 20px;
                color: #858585;

            }

            .link-to-social {
                margin: 0 20px;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: #858585;
            }
        }

        .content-right {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #858585;

        }
    }
}

@media (max-width: 1000px) {
    .footer-main {
        .content:last-child {
            .content-left {
                flex-direction: column;

                .link-to-social {
                    margin-top: 20px;
                }
            }
        }
    }

}

@media (max-width: 650px) {
    .footer-main {
        .content {
            .content-left {
                .link-to-page {
                    margin: 20px 10px 20px 0;
                }

                .link-to-social {
                    margin-top: 20px;
                }
            }
        }
    }
}

@media (max-width: 500px) {
    .footer-main {
        .content {
            .content-left {
                font-size: 12px;

                .link-to-page {
                    margin: 20px 10px 20px 0;
                    font-size: 12px;
                }

                .text-bold {
                    font-size: 12px;
                }

                .link-to-social {
                    margin-top: 20px;
                    font-size: 12px;

                }
            }
        }
    }
}