.main-statistics {
    background-color: #f0f2f5;
    padding: 20px 100px;
    margin-top: 60px;

    .user-statistics {
        background: white;
        padding: 1em;
        border-radius: 10px;
        position: relative;
        box-shadow: var(--color-primary) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;

        .usermanagement-title {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            h5 {
                user-select: none;
                font-size: 2em;
                font-weight: bold;
                text-align: center;
                transition: all 0.3s ease-in-out;
                width: fit-content;
                border-radius: 20px;
                padding: 5px 16px;
                background-color: var(--color-primary);
                color: white;
            }


            .subtitle.bold {
                font-size: 1.5em;
                font-weight: bold;
                text-align: center;
                transition: all 0.3s ease-in-out;
            }

            .subtitle {
                font-size: 1.5em;
                font-weight: 400;
                text-align: center;
                transition: all 0.3s ease-in-out;
            }
        }

        .usermanagement-filter {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 1em;
            gap: 1em;

            .ant-input {
                width: 20vw;
                display: flex;
                align-items: center;
                color: #4E4E4E;
            }

            .ant-input:hover {
                outline: none;
                border: 1px solid #FF6600;
            }

            .ant-input:focus {
                outline: none;
                border: 1px solid #FF6600;
                box-shadow: 0 0 0 1px #FF9955;
                caret-color: #FF9955;
            }

            .ant-select {
                width: 200px;
                display: flex;
                align-items: center;
                color: #4E4E4E;

                .ant-select-selector {
                    border-radius: 20px;
                    border: 1px solid var(--color-primary);

                    .ant-select-selection-item {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        color: var(--color-primary);
                        font-size: 20px;
                        font-weight: bold;
                    }


                }

                span.ant-select-arrow {
                    color: var(--color-primary) !important;
                }
            }
        }

        .year-management__content {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 1em;
            margin-top: 1em;
        }

        // Tạo ra labels có position absolute để đặt phải của chart
        .charts {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin-bottom: 30px;

            .title-chart {
                font-size: 20px;
                font-weight: bold;
                text-align: center;
                margin-bottom: 1em;
            }

            .chart {
                position: relative;
                width: 500px;
                height: 500px;
                margin: 20px 0 50px;

                .chart-polar {
                    position: relative;
                    z-index: 10;

                    .logo-chart {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        background-color: white;
                        border-radius: 50%;
                    }
                }

                .chart-dough {
                    width: 580px;
                    height: 580px;
                    // z-index: 1;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }

            .labels {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 1em;

                .labels__item {
                    display: flex;
                    gap: 1em;
                    align-items: center;

                    .labels__item__color {
                        width: 1em;
                        height: 1em;
                        background-color: red;
                    }

                    .labels__item__text {
                        font-size: 1em;
                        font-weight: 700;
                    }
                }

            }
        }
    }
}

@media (max-width: 400px) {
    .main-statistics {
        background-color: #f0f2f5;
        padding: 0px 10px;
        margin-top: 60px;

        .user-statistics {
            background: white;
            padding: 1em;
            border-radius: 10px;
            position: relative;
            box-shadow: var(--color-primary) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;

            .usermanagement-title {
                h5 {
                    user-select: none;
                    font-size: 16px;
                    font-weight: bold;
                    text-align: center;
                    transition: all 0.3s ease-in-out;
                }

                .subtitle.bold {
                    font-size: 14px;
                    font-weight: bold;
                    text-align: center;
                    transition: all 0.3s ease-in-out;
                    margin-bottom: .5em;
                }

                .subtitle {
                    font-size: 14px;
                    font-weight: 400;
                    text-align: center;
                    transition: all 0.3s ease-in-out;
                }
            }

            .usermanagement-filter {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 1em;
                gap: 1em;

                .ant-input {
                    width: 20vw;
                    display: flex;
                    align-items: center;
                    color: #4E4E4E;
                }

                .ant-input:hover {
                    outline: none;
                    border: 1px solid #FF6600;
                }

                .ant-input:focus {
                    outline: none;
                    border: 1px solid #FF6600;
                    box-shadow: 0 0 0 1px #FF9955;
                    caret-color: #FF9955;
                }

                .ant-select {
                    width: 100px;
                    display: flex;
                    align-items: center;
                    color: #4E4E4E;

                    .ant-select-selector {
                        border-radius: 20px;
                        border: 1px solid var(--color-primary);

                        .ant-select-selection-item {
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            color: var(--color-primary);
                            font-size: 14px;
                            font-weight: bold;
                        }


                    }

                    span.ant-select-arrow {
                        color: var(--color-primary) !important;
                    }
                }
            }

            .year-management__content {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 1em;
                margin-top: 1em;
            }

            // Tạo ra labels có position absolute để đặt phải của chart
            .charts {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                margin-bottom: 30px;

                .title-chart {
                    font-size: 16px;
                    font-weight: bold;
                    text-align: center;
                    margin-bottom: 1em;
                }

                .chart {
                    position: relative;
                    width: 250px;
                    height: 250px;

                    .chart-polar {
                        position: relative;
                        z-index: 10;

                        .logo-chart {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            background-color: white;
                            border-radius: 50%;

                            img {
                                width: 30px;
                            }
                        }
                    }

                    .chart-dough {
                        width: 270px;
                        height: 270px;
                        // z-index: 1;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }

                .labels {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    gap: 10px;

                    .labels__item {
                        display: flex;
                        gap: 5px;
                        align-items: center;

                        .labels__item__color {
                            width: 10px;
                            height: 10px;
                            background-color: red;
                        }

                        .labels__item__text {
                            font-size: 8px;
                            font-weight: 700;
                        }
                    }

                }
            }
        }
    }
}

.apexcharts-toolbar {
    // display: none !important;
}

.apexcharts-title-text {
    font-family: 'Montserrat' !important;
}

.apexcharts-text {
    font-family: 'Montserrat' !important;
}