@import '~antd/dist/antd.css';
@import "../../index.css";

.about-main {
    font-family: 'Montserrat';
    font-style: normal;
    background-color: white;

    .intro-part {
        display: flex;
        justify-content: space-around;
        // border: solid;
        z-index: 2;
        position: relative;
        background: #FFFFFF;
        padding-bottom: 50px;

        .text-of-intro {
            margin-left: 99px;
            margin-top: 100px;
            margin-right: 109px;

            .title {
                font-weight: 700;
                font-size: 40px;
                line-height: 56px;
                color: #1E1E1E;
                margin-bottom: 10px;
            }

            .heading-2 {
                font-weight: 700;
                font-size: 16px;
                line-height: 30px;
                color: #4E4E4E;
            }

            .detail {
                font-weight: 400;
                font-size: 14px;
                line-height: 30px;
                color: #767676;
            }

            .button-start {
                background: linear-gradient(308.85deg, #FF6600 0%, #FF9955 100%);
                border-radius: 30px;
                color: #FFFFFF;
                border: none;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;

            }

            .button-start:hover {
                font-weight: bold;
                background: linear-gradient(308.85deg, #FF6600 0%, #fa7b26 100%);
                transition: 0.3s all;
            }
        }

        .image-of-intro {
            margin-top: 150px;
            margin-right: 75px;
            margin-bottom: 77px;
        }
    }

    #about-2 {
        .about-item {
            margin-bottom: 20px;
            margin-top: 20px;
            background-color: white;
            padding: 30px 30px;
            padding-bottom: 0px;
            box-shadow: 1px 10px 100px rgba(255, 153, 85, 0.15);
            height: 100%;
            border-radius: 30px;
            cursor: pointer;

            i {
                font-size: 43px;
                margin: 0;
            }

            h3 {
                text-align: center;
                font-weight: 700;
                font-size: 30px;
                line-height: 56px;
                color: #1E1E1E;
                margin-bottom: 10px;
            }

            hr {
                width: 46px;
                height: 5px !important;
                background-color: var(--color-primary);
                margin: 0 auto;
                border: none;
                text-align: center;
            }

            p {
                font-weight: 400;
                font-size: 14px;
                line-height: 30px;
                color: #767676;
            }

            &:hover {
                background-color: var(--color-primary);

                i {
                    color: #fff;
                    transform: translateY(-20px);
                    transition: all 400ms ease-in-out;
                }

                h3 {
                    color: #fff;
                    transition: all 400ms ease-in-out;
                }

                p {
                    color: #fff;
                }

                hr {
                    background-color: white;
                    transition: all 400ms ease-in-out;
                }
            }
        }
    }

    .right-of-user {
        margin-top: 50px;
        padding: 40px;
        height: 400px;
        z-index: 1;
        position: relative;

        .title {
            text-align: center;
            font-weight: 700;
            font-size: 40px;
            line-height: 56px;
            color: #1E1E1E;
        }

        .right-lst {
            display: flex;
            justify-content: center;
            align-content: center;

            .lst-item {
                padding: 10px 20px;
                margin: 15px;
                display: flex;
                justify-content: start;
                align-items: center;
                background: #EEF2F6;
                border-radius: 30px;

                .item-icon {
                    height: 100%;
                    width: 40px;
                    margin-right: 15px;
                }

                .item-text {
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 56px;
                    color: #4E4E4E;
                }
            }
        }
    }

    .privacy-legal {
        display: flex;
        z-index: 2;
        position: relative;
        justify-content: space-around;
        background: #FFFFFF;
        padding: 40px;
        padding-bottom: 100px;

        .content {
            display: flex;
            width: 100%;
            justify-content: space-around;
            align-content: center;
            align-items: center;

            .content-box {
                width: 350px;
                height: 180px;
                background-image: url("../../images/background_image_1.png");
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                border-radius: 30px;
                position: relative;
                cursor: pointer;

                .title {
                    margin: 40px 0 20px 40px;
                    font-weight: 700;
                    font-size: 20px;
                    color: #FFFFFF;
                }

                .button {
                    background: #FFFFFF;
                    border-radius: 30px;
                    color: #FF9955;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    border: none;
                    margin-left: 40px;
                }

                .button:hover {
                    border: none;
                    font-weight: 700;
                    transition: all 0.3s;

                }

                .image {
                    position: absolute;
                    top: 70px;
                    right: -35px;
                    width: 150px;
                    height: 150px;
                }
            }

            .content-box:nth-child(2) {
                background-image: url("../../images/background_image_2.png");

                .button {
                    color: #ff6e83;
                }
            }
        }
    }
}

@media (max-width: 1200px) {
    .about-main {
        .intro-part {
            .text-of-intro {
                margin-right: 20px;

                .title {
                    font-size: 35px;
                }

                .heading-2 {
                    font-size: 15px;
                }

                .detail {
                    font-size: 13px;
                }
            }

            .image-of-intro {
                margin-top: 200px;

                .about-image-1 {
                    width: 350px;
                    height: 315px;
                }
            }
        }
    }
}

@media (max-width: 1025px) {
    .about-main {
        .intro-part {
            .text-of-intro {
                margin-right: 20px;

                .title {
                    font-size: 30px;
                }

                .heading-2 {
                    font-size: 13px;
                }

                .detail {
                    font-size: 12px;
                }
            }

            .image-of-intro {
                margin-top: 300px;

                .about-image-1 {
                    width: 300px;
                    height: 265px;
                }
            }
        }
    }
}

@media (max-width: 860px) {
    .about-main {
        .intro-part {
            .text-of-intro {
                .title {
                    font-size: 30px;
                }

                .heading-2 {
                    font-size: 15px;
                }

                .detail {
                    font-size: 13px;
                }
            }

            .image-of-intro {
                display: none !important;

                .about-image-1 {
                    display: none !important;

                }
            }
        }

        #about-2 {
            .col-md-4 {
                margin-bottom: 20px !important;
            }
        }

        .right-of-user {
            height: 350px;

            .title {
                font-size: 30px;
            }

            .right-lst {
                .lst-item {
                    padding: 5px 10px;

                    .item-icon {
                        width: 30px;
                    }

                    .item-text {
                        font-size: 15px;
                    }
                }
            }
        }

        .privacy-legal {
            padding: 30px 0px;

            .content {
                .content-box {
                    width: 250px;
                    height: 150px;

                    .image {
                        top: 80px;
                        right: -20px;
                        width: 100px;
                        height: 100px;
                    }
                }
            }
        }
    }

}

@media (max-width: 560px) {
    .about-main {
        .intro-part {
            .text-of-intro {
                margin-left: 35px;
                margin-right: 60px;

                .title {
                    font-size: 25px;
                }

                .heading-2 {
                    font-size: 13px;
                }

                .detail {
                    font-size: 11px;
                }
            }

            .image-of-intro {
                display: none !important;

                .about-image-1 {
                    display: none !important;

                }
            }
        }

        #about-2 {
            .about-item {
                h3 {
                    font-size: 25px;
                }

                p {
                    font-size: 11px;
                }
            }
        }

        .privacy-legal {
            padding: 30px 0px;

            .content {
                .content-box {
                    width: 200px;
                    height: 125px;

                    .title {
                        margin: 20px 0 10px 30px;
                        font-size: 15px;
                    }

                    .button {
                        font-size: 12px;
                        margin-left: 30px;
                        padding: 5px;
                    }

                    .image {
                        top: 70px;
                        right: -15px;
                        width: 75px;
                        height: 75px;
                    }
                }
            }
        }
    }
}

@media (max-width: 450px) {
    .about-main {
        .right-of-user {
            height: 350px;

            .title {
                font-size: 20px;
            }

            .right-lst {
                .lst-item {
                    .item-text {
                        font-size: 13px;
                    }
                }
            }
        }

        .privacy-legal {
            padding: 30px 0px;

            .content {
                .content-box {
                    width: 150px;
                    height: 100px;

                    .title {
                        margin: 15px 0 10px 20px;
                        font-size: 12px;
                    }

                    .button {
                        font-size: 10px;
                        margin-left: 20px;
                        padding: 5px;
                    }

                    .image {
                        top: 60px;
                        right: -8px;
                        width: 55px;
                        height: 55px;
                    }
                }
            }
        }
    }
}

@media (max-width: 360px) {
    .about-main {
        .intro-part {
            .text-of-intro {
                margin-left: 35px;
                margin-right: 60px;

                .title {
                    font-size: 20px;
                }

                .heading-2 {
                    font-size: 12px;
                }

                .detail {
                    font-size: 10px;
                }
            }

            .image-of-intro {
                display: none !important;

                .about-image-1 {
                    display: none !important;

                }
            }
        }

        .right-of-user {
            height: 350px;

            .title {
                font-size: 20px;
            }

            .right-lst {
                .lst-item {
                    .item-text {
                        font-size: 11px;
                    }
                }
            }
        }

        .privacy-legal {
            padding: 30px 0px;

            .content {
                .content-box {
                    width: 120px;
                    height: 75px;

                    .title {
                        margin: 10px 0 5px 15px;
                        font-size: 10px;
                    }

                    .button {
                        font-size: 7px;
                        margin-left: 10px;
                        padding: 5px;
                    }

                    .image {
                        top: 45px;
                        right: -10px;
                        width: 40px;
                        height: 40px;
                    }
                }
            }
        }
    }
}