@import '~antd/dist/antd.css';
@import '../../index.css';

.judgement-main {
    margin-top: 50px;
    background-color: white;
    font-family: 'Montserrat';
    font-style: normal;

    .intro-part {
        display: flex;
        justify-content: space-around;
        // border: solid;
        z-index: 2;
        position: relative;
        background: #FFFFFF;

        .text-of-intro {
            margin-left: 100px;
            margin-top: 100px;

            .title {
                font-weight: 640;
                font-weight: 700;
                font-size: 30px;
                color: #1E1E1E;
                margin-top: -50px;
            }

            .detail {
                margin-top: 10px;
                margin-bottom: 20px;
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
                color: #858585;

                li {
                    font-size: 12px;
                }
            }

            .detail.info {
                margin-bottom: 0;
            }

            .detail.position {
                font-weight: 700;
            }

            .button-start {
                width: 200px;
            }

            .button-start button {
                background: var(--color-primary);
                border-radius: 30px;
                color: #FFFFFF;
                border: none;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
            }


            .button-start button:hover {
                font-weight: 700;
            }
        }

        .image-of-intro {
            display: flex;
            justify-content: end;
            align-items: center;
            margin-right: 40px;
            margin-left: 100px;

            img {
                height: 80%;
            }
        }
    }

    .criteria-lst {
        margin-top: 33px;
        margin-right: 100px;
        margin-left: 100px;

        .ant-breadcrumb {
            .ant-breadcrumb-link {
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
                color: #C0C0C0;
            }

            .present-link {
                font-weight: 700;
                font-size: 14px;
                line-height: 24px;
                color: var(--color-primary);
            }
        }

        .ant-list {
            margin-top: 20px;

            .ant-list-item {
                border: none;

                .ant-card {
                    // background-image: url("../../images/bg-lock-card.png");
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    border-radius: 30px;

                    .ant-card-body {
                        position: relative;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        height: 350px;
                        border-radius: 20px;


                        .card-img-option {
                            width: 100px;
                            height: 100px;
                            position: absolute;
                            top: 20px;
                        }

                        .card-title-option {
                            position: absolute;
                            top: 140px;
                            font-weight: 700;
                            font-size: 16px;
                            line-height: 32px;
                            text-align: center;
                            color: #FFFFFF;
                        }

                        .card-des-option {
                            position: absolute;
                            top: 170px;
                            font-weight: 700;
                            font-size: 16px;
                            line-height: 32px;
                            text-align: center;
                            color: #FFFFFF;
                        }

                        .card-number-of-question-complete {
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 20px;
                            text-align: center;
                            color: #FFFFFF;
                            margin-top: 130px;
                        }

                        .card-progress-option {
                            margin: 10px 0px;

                            .ant-progress-outer {
                                margin-right: 0;
                                padding-right: 0;

                                .ant-progress-inner {
                                    background-color: rgba(255, 255, 255, 0.297);

                                    .ant-progress-bg {
                                        background-color: white;
                                    }
                                }
                            }

                            .ant-progress-text {
                                display: none;
                            }
                        }

                        .card-description-option {
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 16px;
                            text-align: center;
                            color: #FFFFFF;
                            margin-bottom: 10px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 3;
                            /* number of lines to show */
                            line-clamp: 3;
                            -webkit-box-orient: vertical;
                        }

                        .button-container {
                            position: absolute;
                            bottom: 20px;
                            padding: 0px 15px;

                            .card-btn-option {
                                width: 100%;
                                background: #FFFFFF;
                                border-radius: 30px;
                                font-weight: 700;
                                font-size: 14px;
                                line-height: 20px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                text-align: center;
                                color: #00000050;
                                border: none;
                            }

                            .card-btn-option:active {
                                background: #FFFFFF;
                                color: #00000050;
                            }

                            .card-btn-option:disabled {
                                background: rgba(255, 255, 255, 0.503);
                            }

                            .card-btn-option.unlock {
                                color: var(--color-primary);
                            }
                        }

                    }
                }

                .ant-card.unLock {
                    // background-image: url("../../images/bg-unlock-card.png") !important;
                }
            }

            .ant-list-item.index0 {
                .ant-card {
                    .ant-card-body {
                        background-color: #EF5350;
                    }
                }
            }

            .ant-list-item.index1 {
                .ant-card {
                    .ant-card-body {
                        background-color: #FFA726;
                    }
                }
            }

            .ant-list-item.index2 {
                .ant-card {
                    .ant-card-body {
                        background-color: #FFD54F;
                    }
                }
            }

            .ant-list-item.index3 {
                .ant-card {
                    .ant-card-body {
                        background-color: #66BB6A;
                    }
                }
            }

            .ant-list-item.index4 {
                .ant-card {
                    .ant-card-body {
                        background-color: #42A5F5;
                    }
                }
            }

            .ant-list-item.index5 {
                .ant-card {
                    .ant-card-body {
                        background-color: #5C6BC0;
                    }
                }
            }

            .ant-list-item.index6 {
                .ant-card {
                    .ant-card-body {
                        background-color: #AB47BC;
                    }
                }
            }

            .ant-list-item.index7 {
                .ant-card {
                    .ant-card-body {
                        background-color: #EC407A;
                    }
                }
            }

            .lst-item {
                border-radius: 30px;
                padding: 40px;

                .criteria-image {
                    width: 183px;
                    margin-left: -131px;
                    margin-right: 46px;

                }

                .criteria-title {
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 32px;
                    color: #FFFFFF;
                }

                .criteria-text {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 32px;
                    color: #FFFFFF;
                }

                .criteria-btn {
                    border-radius: 30px !important;
                    background-color: white;
                    color: #1E1E1E;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 20px;
                    margin-top: 10px;
                }

                .criteria-btn:hover {
                    border: none;
                    font-weight: bold;
                    animation: pulse 2s;
                    box-shadow: 0 0 0 5em transparent;
                    transition: all 0.3s;
                }
            }

            .ant-list-item:nth-child(1) {
                .lst-item {
                    background: linear-gradient(90deg, rgba(166, 52, 255, 1) 20%, rgba(180, 84, 255, 1) 50%);

                    .criteria-btn {
                        color: rgba(166, 52, 255, 1);
                    }
                }
            }

            .ant-list-item:nth-child(2) {
                .lst-item {
                    background: linear-gradient(90deg, rgba(12, 203, 186, 1) 20%, rgba(38, 215, 200, 1) 50%);

                    .criteria-btn {
                        color: rgba(12, 203, 186, 1);
                    }
                }
            }

            .ant-list-item:nth-child(3) {
                .lst-item {
                    background: linear-gradient(90deg, rgba(254, 174, 63, 1) 20%, rgba(255, 188, 96, 1) 50%);

                    .criteria-btn {
                        color: rgba(254, 174, 63, 1);
                    }
                }
            }

            .ant-list-item:nth-child(4) {
                .lst-item {
                    background: linear-gradient(90deg, rgba(81, 119, 252, 1) 20%, rgba(101, 134, 253, 1) 50%);

                    .criteria-btn {
                        color: rgba(81, 119, 252, 1);
                    }
                }
            }

            .ant-list-item:nth-child(5) {
                .lst-item {
                    background: linear-gradient(90deg, rgba(255, 121, 141, 1) 20%, rgba(255, 142, 158, 1) 50%);

                    .criteria-btn {
                        color: rgba(255, 121, 141, 1);
                    }
                }
            }

            .ant-list-item:nth-child(6) {
                .lst-item {
                    background: linear-gradient(90deg, rgba(255, 129, 45, 1) 20%, rgba(255, 151, 82, 1) 50%);

                    .criteria-btn {
                        color: rgba(255, 129, 45, 1);
                    }
                }
            }

            .ant-list-item:nth-child(7) {
                .lst-item {
                    background: linear-gradient(90deg, rgba(241, 102, 70, 1) 20%, rgba(243, 118, 89, 1) 50%);

                    .criteria-btn {
                        color: rgba(241, 102, 70, 1);
                    }
                }
            }

            .ant-list-item:nth-child(8) {
                .lst-item {
                    background: linear-gradient(90deg, rgba(38, 224, 105, 1) 20%, rgba(69, 255, 147, 1) 50%);

                    .criteria-btn {
                        color: rgba(38, 224, 105, 1);
                    }
                }
            }
        }

        .content-btn-show {
            display: flex;
            justify-content: center;
            padding: 20px;
            align-items: center;

            .bnt-show-result {
                border: 2px solid #DFDFDF;
                border-radius: 30px;
                color: #DFDFDF;
                font-weight: 700;
                font-size: 14px;
                line-height: 20px;
                display: flex;
                align-items: center;
                text-align: right;
            }

            .bnt-show-result.active {
                border-radius: 30px;
                border: 2px solid var(--color-primary);
                color: var(--color-primary);
                font-weight: 700;
                font-size: 14px;
                line-height: 20px;
                display: flex;
                align-items: center;
                text-align: right;
            }
        }

        .uimpact-options {
            .uimpact-options-title {
                font-weight: 700;
                font-size: 1.5em;
                line-height: 56px;
                display: flex;
                align-items: center;
                text-align: center;
                color: #4E4E4E;
            }

            .uimpact-mandatory-options {
                .uimpact-mandatory-list-item {
                    margin-top: -1.5em;
                }
            }
        }
    }

    .taking-test {
        margin: 40px 75px;

        .ant-breadcrumb {
            .ant-breadcrumb-link {
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
                color: #C0C0C0;
            }

            .present-link {
                font-weight: 700;
                font-size: 14px;
                line-height: 24px;
                color: var(--color-primary);
            }
        }

        .test-body {
            display: flex;

            .test-detail {
                width: 300%;

                .title {
                    font-weight: 700;
                    font-size: 30px;
                    color: #1E1E1E;
                    margin-top: 30px;
                }

                .text {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 16px;
                    display: flex;
                    align-items: center;
                    color: #858585;
                }

                .test-content {
                    display: flex;
                    justify-content: space-between;
                }

                .taking-test-area {
                    border-radius: 30px;
                    background: radial-gradient(58.07% 58.07% at 50% 50%, rgba(255, 236, 229, 0.5) 0%, rgba(255, 236, 229, 0) 100%);
                    border: 3px solid #FFFFFF;
                    box-shadow: 0px 1px 50px rgba(255, 153, 85, 0.15);
                    border-radius: 30px;
                    padding: 30px;
                    width: 65%;
                    margin: 30px 0px;
                    position: relative;

                    .sub-title {
                        font-size: 18px;
                        display: flex;
                        align-items: center;
                        color: var(--color-primary);
                        margin-bottom: 10px;
                        font-weight: bold;
                    }

                    .sub-mini-title {
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 16px;
                        display: flex;
                        align-items: center;
                        color: #858585;
                        margin-bottom: 10px;
                    }

                    .question-lst {
                        .content {
                            font-weight: 500;
                            font-size: 15px;
                            line-height: 20px;
                            color: #4E4E4E;

                        }

                        .options-of-answer {
                            display: flex;
                            background: #fff9f7;
                            width: fit-content;
                            border-radius: 20px;
                            padding: 0 2em;
                            margin: 10px 0;

                            .lst-item {
                                display: flex;
                                margin: 10px 0;
                                margin-right: 30px;
                                align-items: center;
                                position: relative;

                                &:hover>.label {
                                    color: var(--color-primary);

                                    &::before {
                                        border: 0.25rem solid var(--color-primary);
                                        margin-right: 1rem;
                                    }
                                }


                                .radio-btn {
                                    position: absolute;
                                    opacity: 0;
                                    visibility: hidden;

                                    &:checked+.label {
                                        color: var(--color-primary);

                                        &::before {
                                            margin-right: 1rem;
                                            border: 0.25rem solid var(--color-primary);
                                            background: #EEF2F6;
                                        }
                                    }
                                }

                                .label {
                                    display: flex;
                                    align-items: center;
                                    padding: 0.7rem 0;
                                    color: #EEF2F6;
                                    cursor: pointer;
                                    transition: all 0.25s linear;
                                    font-weight: 500;
                                    font-size: 12px;
                                    line-height: 20px;
                                    color: #858585;
                                }

                                .label::before {
                                    display: inline-block;
                                    content: "";
                                    height: 15px;
                                    width: 15px;
                                    padding: 5px;
                                    margin-right: 0.625rem;
                                    border: 2px solid #858585;
                                    border-radius: 50%;
                                    transition: all 0.25s linear;
                                }

                            }
                        }
                    }


                    .footer {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .pagination {
                            // pointer-events: none;
                        }

                        .paginnation.paginnation-result {
                            pointer-events: auto !important;
                        }

                        .button-group {
                            display: flex;
                            align-items: center;

                            .number-of-questions-answered {
                                margin-right: 10px;
                            }

                            .button {
                                // background: var(--color-primary);
                                border-radius: 30px;
                                color: var(--color-primary);
                                background-color: white;
                                border: 2px solid var(--color-primary);
                                font-weight: 700;
                                font-size: 14px;
                                line-height: 20px;
                            }

                            .button:first-child {
                                margin-right: 10px;
                            }

                            .button:hover {
                                background: var(--color-primary);
                                transition: 0.3s all;
                                color: white;
                            }
                        }
                    }
                }



                .note-taking-test {
                    width: 30%;
                    margin: 30px 0px;
                    background: var(--bg-note);
                    border-radius: 30px;
                    padding: 30px;

                    .note-title {
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 20px;
                        display: flex;
                        align-items: center;
                        color: #4E4E4E;
                    }

                    .note-content {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                        display: flex;
                        flex-direction: column;
                        color: #4E4E4E;
                        margin: 10px 0px;

                        li {
                            list-style-type: "-    ";
                        }

                        li::marker {
                            margin-right: 5px !important;
                        }
                    }

                    .note-content-items {
                        display: flex;
                        flex-direction: column;
                        gap: 10px;

                        .note-content-item {
                            display: flex;
                            gap: 10px;
                            font-weight: 400;

                            font-size: 14px;
                            line-height: 20px;
                            color: #4E4E4E;
                            margin: 10px 0px;

                            span {
                                color: var(--color-primary);
                                font-size: 2em;
                            }
                        }
                    }
                }
            }

            .other-tests {
                margin-left: 20px;
                display: none;

                .test {
                    padding: 22px;
                    margin-bottom: 10px;
                    // border: solid;
                    border-radius: 30px;
                    background-color: #FFFFFF;
                    box-shadow: 0px 2px 10px rgba(4, 6, 15, 0.05);

                    .title {
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 20px;
                        color: #4E4E4E;
                        margin-bottom: 10px;
                    }

                    .content {
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 24px;
                        color: #858585;
                    }

                    .button {
                        background: var(--color-primary);
                        border-radius: 30px;
                        color: #FFFFFF;
                        border: none;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                        margin-right: 20px;

                    }

                    .button:hover {
                        font-weight: bold;
                        background: var(--color-primary);
                        transition: 0.3s all;
                    }
                }

                .more-test {
                    display: flex;
                    justify-content: end;
                    align-items: center;
                    margin-top: 20px;
                    cursor: pointer;

                    .text {
                        font-weight: 700;
                        font-size: 14px;
                        line-height: 20px;
                        display: flex;
                        align-items: center;
                        text-align: right;
                        color: var(--color-primary);
                        margin-right: 10px;
                    }

                    .icon {
                        color: var(--color-primary);
                        margin-bottom: 5px;
                    }
                }

                .more-test:hover {
                    -webkit-animation: swing 1s ease;
                    animation: swing 1s ease;
                    -webkit-animation-iteration-count: 1;
                    animation-iteration-count: 1;

                    .text {
                        font-size: 20px;
                        transition: all 0.3s;
                    }

                    .icon {
                        font-size: 20px;
                        transition: all 0.3s;
                    }
                }
            }
        }
    }

    .more-test {
        .back-test {
            display: flex;
            justify-content: end;
            align-items: center;
            margin: 30px 100px;
            cursor: pointer;

            .text {
                font-weight: 700;
                font-size: 14px;
                line-height: 20px;
                display: flex;
                align-items: center;
                text-align: right;
                color: var(--color-primary);
            }

            .icon {
                color: var(--color-primary);
                margin-right: 10px;
                margin-bottom: 5px;
            }
        }

        .back-test:hover {
            -webkit-animation: swing 1s ease;
            animation: swing 1s ease;
            -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;

            .text {
                font-size: 20px;
                transition: all 0.3s;
            }

            .icon {
                .anticon.anticon-arrow-left {
                    font-size: 20px !important;
                    transition: all 0.3s;
                }
            }
        }

        .test-body {
            .other-tests {
                margin: 0px 100px;

                .test {
                    padding: 22px;
                    margin-bottom: 10px;
                    // border: solid;
                    border-radius: 30px;
                    background-color: #FFFFFF;
                    box-shadow: 0px 2px 10px rgba(4, 6, 15, 0.05);

                    .title {
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 20px;
                        color: #4E4E4E;
                        margin-bottom: 10px;
                    }

                    .content {
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 24px;
                        color: #858585;
                    }

                    .button {
                        background: var(--color-primary);
                        border-radius: 30px;
                        color: #FFFFFF;
                        border: none;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                        margin-right: 20px;

                    }

                    .button:hover {
                        font-weight: bold;
                        background: var(--color-primary);
                        transition: 0.3s all;
                    }
                }

                .more-test {
                    display: flex;
                    justify-content: end;
                    align-items: center;
                    margin-top: 20px;
                    cursor: pointer;

                    .text {
                        font-weight: 700;
                        font-size: 14px;
                        line-height: 20px;
                        display: flex;
                        align-items: center;
                        text-align: right;
                        color: var(--color-primary);
                        margin-right: 10px;
                    }

                    .icon {
                        color: var(--color-primary);
                        margin-bottom: 5px;
                    }
                }

                .more-test:hover {
                    -webkit-animation: swing 1s ease;
                    animation: swing 1s ease;
                    -webkit-animation-iteration-count: 1;
                    animation-iteration-count: 1;

                    .text {
                        font-size: 20px;
                        transition: all 0.3s;
                    }

                    .icon {
                        font-size: 20px;
                        transition: all 0.3s;
                    }
                }
            }
        }
    }

    .result-answer {
        background-color: #f0f2f5;
        padding: 20px 100px;

        .total-score {
            display: flex;
            position: relative;
            justify-content: space-between;
            flex-direction: column;
            z-index: 2;
            // padding: 0px 10em;

            .total-score-left {
                margin-top: 35px;

                .title {
                    font-weight: 700;
                    font-size: 40px;

                    color: #1E1E1E;
                }

                .sub-title {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 24px;
                    display: flex;
                    align-items: center;
                    color: #858585;
                }

                .detail-total {
                    position: relative;

                    .detail-content {
                        padding: 2em;
                        padding-bottom: 0em;
                        background-color: white;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        margin-bottom: 2em;
                        border-radius: 16px;
                        gap: 0em;
                        box-shadow: var(--color-primary) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;

                        .subtitle.bold {
                            font-weight: 700;
                        }

                        .subtitle.last {
                            margin-bottom: -3em;
                        }

                        .content-chart {
                            display: flex;
                        }

                        strong {
                            color: var(--color-primary);
                        }
                    }

                    // .subtitle {
                    //     margin-bottom: -3em;
                    // }

                    .subtitle.criteria {
                        margin-bottom: -3em;
                    }

                    .pf-c-chart {
                        text-align: center;

                        svg {
                            width: 600px !important;

                            g:nth-child(2) {
                                text {
                                    tspan {
                                        font-weight: 700;
                                        font-size: 11px !important;
                                        line-height: 20px;
                                        color: #4E4E4E;
                                        transition: transform 0.3s ease-in-out;
                                    }

                                    tspan:hover {
                                        transform: scale(1.5) !important;
                                        cursor: pointer;
                                    }
                                }
                            }
                        }

                        text {
                            tspan:nth-child(1) {
                                font-weight: 700;
                                // font-size: 30px !important;

                                display: flex;
                                align-items: center;
                                text-align: center;
                                color: #4E4E4E;
                                padding-bottom: 10px;
                            }

                            // tspan:nth-child(2) {
                            //     font-weight: 500;
                            //     font-size: 14px;
                            //     line-height: 24px;
                            //     color: #867F96;
                            // }
                        }
                    }

                    .score-out-of-total-score {
                        position: absolute;
                        top: 58%;
                        left: 50%;
                        transform: translate(-50%, -50%);

                        .score {
                            justify-content: center;
                            font-weight: 700;
                            font-size: 40px;

                            display: flex;
                            align-items: center;
                            text-align: center;
                            color: #4E4E4E;
                        }

                        .total-score-of-test {
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 24px;
                            color: #867F96;
                        }
                    }
                }
            }

            .total-score-right {
                display: flex;
                align-items: center;

                .total-score-right-content {
                    display: flex;
                    flex-direction: column;
                    gap: 1em;
                    width: 100%;

                    .content-score {
                        padding: 1em;
                        background-color: white;
                        border-radius: 16px;
                        box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
                        position: relative;

                        .x-axis,
                        .y-axis {
                            position: absolute;
                            font-size: 12px;
                        }

                        .x-axis {
                            transform: rotate(-90deg);
                            top: 50%;
                            left: 0px;
                        }

                        .y-axis {
                            top: 97%;
                            left: 43%;
                            transform: translate(-50%, -100%);
                        }

                        .label {
                            position: absolute;
                            display: flex;
                            gap: 1em;
                            top: 15px;
                            left: 35%;

                            .label-item {
                                display: flex;
                                align-items: center;
                                gap: 0.5em;

                                .label-color {
                                    width: 16px;
                                    height: 16px;
                                    border-radius: 2px;
                                }

                                .label-name {
                                    font-weight: 500;
                                    font-size: 12px;
                                    line-height: 20px;
                                    color: #858585;
                                }
                            }
                        }

                        // Tạo ra labels có position absolute để đặt phải của chart
                        .charts {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            flex-direction: column;
                            margin-bottom: 30px;

                            .title-chart {
                                font-size: 20px;
                                font-weight: bold;
                                text-align: center;
                                margin-bottom: 1em;
                            }

                            .chart {
                                position: relative;
                                width: 500px;
                                height: 500px;
                                margin: 20px 0 50px;

                                .chart-polar {
                                    position: relative;
                                    z-index: 10;

                                    .logo-chart {
                                        position: absolute;
                                        top: 50%;
                                        left: 50%;
                                        transform: translate(-50%, -50%);
                                        background-color: white;
                                        border-radius: 50%;
                                    }
                                }

                                .chart-dough {
                                    width: 580px;
                                    height: 580px;
                                    // z-index: 1;
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    transform: translate(-50%, -50%);
                                }

                                .chart-bar {
                                    width: 580px;
                                    height: 580px;
                                    // z-index: 1;
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    transform: translate(-50%, -50%);
                                }

                            }

                            .labels {
                                display: grid;
                                grid-template-columns: repeat(2, 1fr);
                                gap: 1em;

                                .labels__item {
                                    display: flex;
                                    gap: 1em;
                                    align-items: center;

                                    .labels__item__color {
                                        width: 1em;
                                        height: 1em;
                                        background-color: red;
                                    }

                                    .labels__item__text {
                                        font-size: 1em;
                                        font-weight: 700;
                                    }
                                }

                            }
                        }

                        .labels {
                            display: grid;
                            grid-template-columns: repeat(2, 1fr);
                            gap: 1em;

                            .labels__item {
                                display: flex;
                                gap: 1em;
                                align-items: center;

                                .labels__item__color {
                                    width: 1em;
                                    height: 1em;
                                    background-color: red;
                                }

                                .labels__item__text {
                                    font-size: 1em;
                                    font-weight: 700;
                                }
                            }

                        }
                    }
                }

                img {
                    height: 80%;
                }
            }
        }

        .total-score.select {
            .total-score-left {

                .detail-total {
                    .detail-content {
                        // display: flex;
                        // flex-direction: row;
                        // justify-content: space-between;
                    }

                    .pf-c-chart {
                        margin-top: -3em;
                    }

                    .result-chart {
                        position: relative;

                        .title-result-chart {
                            position: absolute;
                            top: -1em;
                        }

                        .x-axis,
                        .y-axis {
                            position: absolute;
                            font-size: 12px;
                        }

                        .x-axis {
                            transform: rotate(-90deg);
                            top: 40%;
                            left: -15px;
                        }

                        .y-axis {
                            top: 85%;
                            left: 43%;
                            transform: translate(-50%, -100%);
                        }
                    }
                }

            }
        }

        .result-answers {
            background-color: white;
            padding: 1em;
            border-radius: 16px;
            box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;

            .title-view-test {
                .title {
                    font-weight: 700;
                    font-size: 40px;

                    text-align: center;
                    color: #1E1E1E;
                }

                .content {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 24px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    color: #858585;
                }
            }

            .taking-test-area {
                border-radius: 30px;
                padding: 38px;
                width: 100%;
                margin: 30px auto;

                .sub-title {
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 20px;
                    display: flex;
                    align-items: center;
                    text-transform: uppercase;
                    color: var(--color-primary);
                    margin-bottom: 10px;
                }

                .question-lst {
                    .content {
                        font-weight: 500;
                        font-size: 15px;
                        line-height: 20px;
                        color: #4E4E4E;

                    }

                    .options-of-answer {
                        display: flex;

                        .lst-item {
                            display: flex;
                            margin: 20px 0;
                            margin-right: 30px;
                            align-items: center;
                            position: relative;

                            &:hover>.label {
                                pointer-events: none;

                                // color: var(--color-primary);

                                // &::before {
                                //     border: 0.5rem solid var(--color-primary);
                                //     margin-right: 1rem;
                                // }
                            }


                            .radio-btn {
                                position: absolute;
                                opacity: 0;
                                visibility: hidden;

                                &:checked+.label {
                                    color: var(--color-primary);

                                    &::before {
                                        margin-right: 1rem;
                                        border: 0.5rem solid var(--color-primary);
                                        background: #EEF2F6;
                                    }
                                }
                            }

                            .label {
                                display: flex;
                                align-items: center;
                                padding: 0.75rem 0;
                                color: #EEF2F6;
                                text-transform: uppercase;
                                cursor: pointer;
                                transition: all 0.25s linear;
                                font-weight: 500;
                                font-size: 12px;
                                line-height: 20px;
                                color: #858585;

                            }

                            .label::before {
                                display: inline-block;
                                content: "";
                                height: 20px;
                                width: 20px;
                                padding: 5px;
                                margin-right: 0.625rem;
                                border: 2px solid #858585;
                                border-radius: 50%;
                                transition: all 0.25s linear;
                            }

                        }
                    }
                }

                .footer {
                    display: flex;
                    justify-content: space-between;

                    .pagination {}

                    .button-group {
                        display: flex;

                        .button {
                            // background: var(--color-primary);
                            border-radius: 30px;
                            color: var(--color-primary);
                            background-color: #EEF2F6;
                            border: 2px solid var(--color-primary);
                            font-weight: 700;
                            font-size: 14px;
                            line-height: 20px;
                        }

                        .button:first-child {
                            margin-right: 10px;
                        }

                        .button:hover {
                            background: var(--color-primary);
                            transition: 0.3s all;
                            color: white;
                        }
                    }
                }
            }
        }

        .turn-back-btn {
            display: flex;
            align-items: center;
            margin-top: 10px;

            .select-option {
                display: flex;
                gap: 1em;
            }

            .select-criteria {
                // max-width: 400px;
                width: 300px;

                .ant-select-selector {
                    border-radius: 30px;
                }
            }

            .home-button {
                width: 100px;
            }

            .home-button button {
                background: var(--color-primary);
                border-radius: 30px;
                color: #FFFFFF;
                border: none;
                font-weight: 600;
                // font-weight: 700;
                font-size: 14px;
                line-height: 20px;
            }


            .home-button button:hover {}
        }


    }

    .ant-checkbox-inner {
        border-radius: 224px;
        width: 20px;
        height: 20px;
        margin-right: 10px;
    }

    .ant-pagination-options {
        display: none !important;
    }

    .ant-input {
        width: 10em;
        border-radius: 30px;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        display: flex;
        align-items: center;
        color: #4E4E4E;
        margin: 1em 0;
    }

    .ant-input::-webkit-input-placeholder {
        // color: red;
        margin-left: 10px;
        padding-left: 5px;
        width: 285px !important;
        font-weight: 400;
        font-size: 11px;
        line-height: 24px;
        display: flex;
        align-items: center;
        color: #C0C0C0;

    }


    .ant-input.email-input:hover {
        outline: none;
        border: 1px solid var(--color-primary);
    }

    .ant-input.email-input:focus {
        outline: none;
        border: 1px solid var(--color-primary);
        box-shadow: 0 0 0 1px var(--color-primary);
        caret-color: var(--color-primary);
    }

    .ant-input-password:hover {
        outline: none;
        border: 1px solid var(--color-primary);
    }

    .ant-input-affix-wrapper {
        width: 330px !important;
    }

    .ant-input-affix-wrapper-focused {
        border: 1px solid var(--color-primary);
        box-shadow: 0 0 0 1px var(--color-primary);
        caret-color: var(--color-primary);
    }
}


.judgement-main {
    .taking-test {
        .test-body {
            .test-detail {
                .taking-test-area.UIMPACT {
                    .question-lst {
                        .options-of-answer {
                            display: grid;
                            grid-template-columns: repeat(5, 1fr);
                            grid-template-rows: repeat(1, 1fr);
                            grid-column-gap: 0px;
                            grid-row-gap: 0px;
                            width: 100%;

                            .lst-item {
                                margin: 0;
                                margin-right: 10px;
                            }

                            .lst-item:nth-child(3),
                            .lst-item:nth-child(5) {
                                margin-right: 0;
                            }

                        }

                        .options-of-answer.input-answer {
                            display: flex;
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 1400px) {
    .judgement-main {
        .intro-part {
            padding-bottom: 50px;

            .image-of-intro {
                img {
                    margin-left: -100px !important;
                }
            }
        }
    }

}

@media (max-width: 1400px) {
    .judgement-main {

        .intro-part {
            .image-of-intro {
                img {
                    height: 65%;
                    margin-left: -300px !important;
                }
            }

            .image-of-intro.uimpact {
                img {
                    height: 50%;
                    margin-left: -350px !important;
                }
            }
        }

        .taking-test {
            .test-body {
                .test-detail {
                    .taking-test-area {
                        padding: 20px;
                    }
                }
            }
        }

        .criteria-lst {
            .ant-list {
                .ant-list-item {
                    .ant-card {
                        .ant-card-body {
                            .card-title-option {
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }

        .more-test {
            .back-test {
                margin: 30px;
            }

            .test-body {

                .other-tests {
                    margin: 0px 30px !important;

                    .ant-list {
                        .ant-row {
                            .ant-col {
                                .ant-list-item {
                                    .test {
                                        .btn-and-icon {
                                            cursor: pointer;

                                            img {
                                                margin-right: 20px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .result-answer {

            .total-score {
                .total-score-left {
                    .title {}

                    .sub-title {}

                    .detail-total {
                        .detail-content {
                            .content-chart {
                                margin-top: 20px;
                                margin-bottom: -60px;
                            }

                        }

                        .pf-c-chart {
                            svg:nth-child(1) {}

                            svg {

                                g:nth-child(2) {
                                    text {
                                        tspan {
                                            font-size: 9px !important;
                                        }
                                    }
                                }
                            }

                        }

                        .score-out-of-total-score {
                            .score {}

                            .total-score-of-test {}
                        }
                    }
                }

                .total-score-right {
                    // margin-right: -200px;

                    img {
                        height: 70%;
                    }
                }
            }

            .result-answers {

                .title-view-test {
                    .title {}

                    .content {}
                }

                .taking-test-area {

                    .sub-title {}

                    .question-lst {
                        .content {}

                        .options-of-answer {
                            .lst-item {
                                .radio-btn {}

                                .label {}

                                .label::before {}

                            }
                        }
                    }


                }
            }



            .turn-back-btn {

                .back-button {

                    .text {}

                    .icon {}
                }
            }


        }
    }


}

@media (max-width: 1200px) {
    .judgement-main {
        .criteria-lst {
            margin-right: 25px;
            margin-left: 25px;

            .criteria-image {
                margin-top: 24px;
            }
        }

        .result-answer {

            .total-score {
                .total-score-left {
                    .title {
                        font-size: 30px;
                    }

                    .sub-title {}

                    .detail-total {
                        .detail-content {
                            .content-chart {
                                margin-top: 30px;
                                margin-bottom: -50px;
                            }

                        }

                        .pf-c-chart {
                            svg:nth-child(1) {
                                width: 500px !important;
                            }

                            svg {

                                g:nth-child(2) {
                                    margin-left: -10px;

                                    text {
                                        tspan {
                                            font-size: 10px !important;
                                        }
                                    }
                                }
                            }
                        }

                        .score-out-of-total-score {
                            .score {}

                            .total-score-of-test {}
                        }
                    }

                }

                .total-score-right {
                    justify-content: center;

                    img {
                        height: 60%;
                    }
                }
            }

            .result-answers {

                .title-view-test {
                    .title {}

                    .content {}
                }

                .taking-test-area {

                    .sub-title {}

                    .question-lst {
                        .content {}

                        .options-of-answer {
                            .lst-item {
                                .radio-btn {}

                                .label {}

                                .label::before {}

                            }
                        }
                    }


                }
            }

            .turn-back-btn {

                .back-button {

                    .text {}

                    .icon {}
                }
            }


        }
    }

}

@media (max-width: 1100px) {
    .judgement-main {
        .intro-part {
            .text-of-intro {
                margin-left: 50px;

                .title {
                    font-size: 25px;
                }

                .detail {
                    font-size: 13px;
                    line-height: 20px;
                }
            }

            .image-of-intro {
                margin-right: 50px;

                img {
                    height: 60%;
                    margin-left: -300px !important;
                }
            }
        }

        .taking-test {
            margin: 40px 30px;

            .test-body {

                .test-detail {
                    .title {
                        font-size: 30px !important;
                    }

                    .text {
                        font-size: 13px !important;
                    }

                    .taking-test-area {
                        // .sub-title {
                        //     font-size: 14px;
                        // }

                        // .question-lst {
                        //     .content {
                        //         font-size: 13px;
                        //     }

                        //     .options-of-answer {

                        //         .lst-item {
                        //             margin-right: 10px;

                        //             .label {
                        //                 font-size: 10px;
                        //             }

                        //             .label::before {
                        //                 width: 22.5px !important;
                        //             }
                        //         }
                        //     }
                        // }
                    }
                }
            }

            .other-tests {
                margin-top: 45px !important;

                .test {
                    .title {
                        font-size: 14px !important;
                    }

                    .content {
                        font-size: 12px !important;
                    }

                    .btn-and-icon {
                        margin: 10px !important;
                    }

                    .button {
                        margin-right: 0px !important;
                        margin-left: 15px !important;
                        font-size: 12px !important;
                    }
                }
            }
        }

        .criteria-lst {

            .criteria-title {
                font-size: 18px !important;
            }

            .criteria-text {
                font-size: 12px !important;
            }
        }

        .more-test {
            .test-body {
                .other-tests {
                    margin: 0px 30px !important;

                    .ant-list {
                        .ant-row {
                            .ant-col {
                                .ant-list-item {
                                    .test {
                                        padding: 15px;

                                        .title {
                                            font-size: 14px;
                                        }

                                        .content {
                                            font-size: 12px;
                                        }

                                        .button {
                                            font-size: 12px;
                                        }

                                        .btn-and-icon {
                                            margin: 5px;

                                            img {
                                                margin-right: 10px;
                                                margin-left: -15px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .result-answer {

            .total-score {
                .total-score-left {
                    .title {}

                    .sub-title {}

                    .detail-total {
                        .pf-c-chart {
                            svg:nth-child(1) {
                                width: 500px !important;
                            }
                        }

                        .score-out-of-total-score {
                            .score {}

                            .total-score-of-test {}
                        }
                    }
                }

                .total-score-right {

                    .result-chart {
                        .sub-title-chart {
                            position: absolute;
                            top: 10px;
                        }

                        position: relative;
                        width: 50vw !important;
                    }

                    img {
                        height: 60%;
                    }
                }
            }

            .result-answers {
                .title-view-test {
                    .title {}

                    .content {}
                }

                .taking-test-area {

                    .sub-title {}

                    .question-lst {
                        .content {}

                        .options-of-answer {
                            .lst-item {
                                .radio-btn {}

                                .label {}

                                .label::before {}

                            }
                        }
                    }


                }
            }

            .turn-back-btn {

                .back-button {

                    .text {}

                    .icon {}
                }
            }


        }
    }
}

@media (max-width: 1000px) {
    .judgement-main {
        .intro-part {
            .image-of-intro {
                margin-left: 0px;

                img {
                    height: 50%;
                    // margin-left: px !important;
                }
            }

            .image-of-intro.uimpact {
                display: none;
            }
        }

        .result-answer {
            .total-score {
                justify-content: space-around;

                .total-score-left {
                    .title {}

                    .sub-title {}

                    .detail-total {
                        .pf-c-chart {
                            svg:nth-child(1) {
                                width: 45vw !important;
                            }
                        }

                        .score-out-of-total-score {
                            .score {}

                            .total-score-of-test {}
                        }
                    }
                }

                .total-score-right {
                    .result-chart {
                        width: 45vw !important;
                    }

                    img {
                        height: 40% !important;
                    }
                }
            }

            .result-answers {
                .title-view-test {
                    .title {}

                    .content {}
                }

                .taking-test-area {
                    width: 80%;

                    .sub-title {}

                    .question-lst {
                        .content {}

                        .options-of-answer {
                            .lst-item {
                                .radio-btn {}

                                .label {}

                                .label::before {}

                            }
                        }
                    }


                }

            }

            .turn-back-btn {

                .back-button {

                    .text {}

                    .icon {}
                }
            }


        }
    }

}

@media (max-width: 900px) {
    .judgement-main {
        .intro-part {
            padding-bottom: 50px;

            .text-of-intro {
                margin: 100px 25px 50px 25px;
                margin-top: 100px;
            }

            .image-of-intro {
                display: none !important;
            }
        }

        .criteria-lst {
            margin-right: 25px;
            margin-left: 25px;

            .criteria-image {
                display: none !important;
                ;
            }

            .criteria-title {
                font-size: 20px !important;
            }

            .criteria-text {
                font-size: 16px !important;
            }
        }

        .taking-test {
            margin: 40px 30px;

            .test-body {

                .test-detail {
                    .title {
                        font-size: 30px !important;
                    }

                    .text {
                        font-size: 13px !important;
                    }

                    .taking-test-area {
                        width: 100%;

                        .sub-title {
                            font-size: 14px;
                        }

                        .question-lst {
                            .content {
                                font-size: 13px;
                            }

                            .options-of-answer {

                                .lst-item {
                                    margin-right: 10px;

                                    .label {
                                        font-size: 10px;
                                    }

                                    .label::before {
                                        // width: 22.5px !important;
                                    }
                                }
                            }
                        }

                        .footer {
                            .button-group {
                                .number-of-questions-answered {
                                    font-size: 12px;
                                }
                            }
                        }

                    }

                    .note-taking-test {
                        display: none;
                    }
                }
            }

            .other-tests {
                margin-top: 45px !important;
                display: none !important;

                .test {

                    // display: none !important;
                    .title {
                        font-size: 14px !important;
                    }

                    .content {
                        font-size: 12px !important;
                    }

                    .btn-and-icon {
                        margin: 10px !important;
                    }

                    .button {
                        margin-right: 0px !important;
                        margin-left: 15px !important;
                        font-size: 12px !important;
                    }
                }
            }
        }

        .more-test {
            .test-body {
                .other-tests {
                    margin: 0px 30px !important;

                    .ant-list {
                        .ant-row {
                            div {
                                .ant-col {
                                    .ant-list-item {
                                        .test {
                                            padding: 15px;

                                            .title {
                                                font-size: 14px;
                                            }

                                            .content {
                                                font-size: 12px;
                                            }

                                            .button {
                                                font-size: 12px;
                                            }

                                            .btn-and-icon {
                                                margin: 5px;

                                                img {
                                                    margin-right: 10px;
                                                    margin-left: -15px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                        }
                    }
                }
            }
        }

        .result-answer {
            padding: 0px 2rem;

            .total-score {
                justify-content: center;
                flex-direction: column;

                .total-score-left {
                    .title {}

                    .sub-title {}

                    .detail-total {
                        .pf-c-chart {
                            display: flex !important;
                            justify-content: center;
                            text-align: center;

                            svg:nth-child(1) {
                                width: 70vw !important;
                            }
                        }

                        .score-out-of-total-score {
                            .score {}

                            .total-score-of-test {}
                        }

                        .detail-content {
                            .content-chart {
                                flex-direction: column;
                            }
                        }
                    }
                }

                .total-score-right {
                    margin-right: 0px;

                    .result-chart {
                        width: 80vw !important;
                    }

                    .pf-c-chart {
                        svg {
                            g:nth-child(2) {}
                        }
                    }

                    img {
                        height: 55% !important;
                    }
                }
            }

            .result-answers {
                .title-view-test {
                    .title {}

                    .content {}
                }

                .taking-test-area {

                    .sub-title {
                        font-size: 14px;
                    }

                    .question-lst {
                        .content {
                            font-size: 13px;
                        }

                        .options-of-answer {

                            .lst-item {
                                margin-right: 10px;

                                .label {
                                    font-size: 10px;
                                }

                                .label::before {
                                    // width: 22.5px !important;
                                }
                            }
                        }
                    }


                }

            }

            .turn-back-btn {

                .back-button {

                    .text {}

                    .icon {}
                }
            }


        }
    }
}

@media (max-width: 700px) {
    .judgement-main {
        margin-top: 40px;
    }
}

@media (max-width: 600px) {
    .judgement-main {
        .intro-part {
            padding-bottom: 50px;
            margin-top: 10px;

            .text-of-intro {
                margin: 100px 25px 50px 25px;
                margin-top: 50px;

                .title {
                    font-size: 20px;
                }

                .detail {
                    font-size: 12px;
                }

                .button-start {
                    font-size: 10px;
                }
            }

            .image-of-intro {
                display: none !important;
            }
        }

        .criteria-lst {
            margin-right: 25px;
            margin-left: 25px;

            .lst-item {
                padding: 20px !important;

                .criteria-image {
                    display: none !important;
                    ;
                }

                .criteria-title {
                    font-size: 16px !important;
                }

                .criteria-text {
                    font-size: 12px !important;
                }

                .criteria-btn {
                    font-size: 12px !important;
                }
            }

        }

        .taking-test {
            margin: 40px 30px;

            .test-body {

                .test-detail {
                    .title {
                        font-size: 25px !important;
                    }

                    .text {
                        font-size: 12px !important;
                    }

                    .taking-test-area {
                        width: 100%;

                        .sub-title {
                            font-size: 12px;
                        }

                        .question-lst {
                            .content {
                                font-size: 11px;
                            }

                            .options-of-answer {

                                .lst-item {
                                    margin-right: 10px;

                                    &:hover>.label {
                                        &::before {
                                            border: 5px solid var(--color-primary);
                                            margin-right: 5px;
                                            width: 20px !important;
                                        }
                                    }

                                    .radio-btn {
                                        &:checked+.label {
                                            &::before {
                                                margin-right: 5px;
                                                border: 5px solid var(--color-primary);
                                                background: #EEF2F6;
                                                width: 20px !important;

                                            }
                                        }
                                    }

                                    .label {
                                        font-size: 8px;
                                    }

                                    .label::before {
                                        // width: 22px !important;
                                        height: 15px;
                                        width: 15px;
                                    }
                                }
                            }
                        }

                        .footer {
                            flex-direction: column;

                            .pagination {
                                margin-bottom: 15px;
                            }
                        }
                    }
                }
            }
        }

        .result-answer {
            padding: 0px 1rem;

            .total-score {
                .total-score-left {
                    .title {
                        font-size: 25px;
                    }

                    .sub-title {
                        font-size: 12px;
                    }

                    .detail-total {
                        .pf-c-chart {
                            svg {
                                &:nth-child(1) {
                                    width: 400px !important;
                                }
                            }
                        }
                    }

                }

                .total-score-right {

                    .result-chart {
                        width: 100vw !important;
                    }
                }
            }

            .result-answers {
                .title-view-test {
                    .title {
                        font-size: 25px;
                    }

                    .content {
                        font-size: 12px;
                    }
                }

                .taking-test-area {
                    width: 90%;

                    .sub-title {
                        font-size: 12px;
                    }

                    .question-lst {
                        .content {
                            font-size: 11px;
                        }

                        .options-of-answer {

                            .lst-item {
                                margin-right: 10px;

                                &:hover>.label {
                                    // &::before {
                                    //     border: 5px solid var(--color-primary);
                                    //     margin-right: 5px;
                                    //     width: 20px !important;
                                    // }
                                }

                                .radio-btn {
                                    &:checked+.label {
                                        &::before {
                                            margin-right: 5px;
                                            border: 5px solid var(--color-primary);
                                            background: #EEF2F6;
                                            width: 20px !important;

                                        }
                                    }
                                }

                                .label {
                                    font-size: 8px;
                                }

                                .label::before {
                                    // width: 22px !important;
                                }
                            }
                        }
                    }
                }
            }
        }

    }
}

@media (max-width: 450px) {
    .judgement-main {
        .criteria-lst {
            .ant-list {
                .ant-list-item {
                    &:nth-child(1) {
                        .lst-item {
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }

        .taking-test {
            .test-body {
                .test-detail {
                    .taking-test-area {
                        .question-lst {
                            .options-of-answer {
                                .lst-item {
                                    .label {
                                        &::before {
                                            height: 10px;
                                            width: 10px;
                                        }
                                    }
                                }
                            }
                        }

                        .footer {
                            .button-group {
                                flex-direction: column;

                                .number-of-questions-answered {
                                    font-size: 14px;
                                }

                                .button {
                                    font-size: 12px !important;
                                }

                                .taking-test-area {
                                    margin: 10px 0;
                                }

                                // .taking-test-button:last-child {
                                //     margin: 0px !important;
                                // }
                            }

                        }
                    }
                }
            }
        }

        .result-answer {
            .total-score {
                .total-score-left {
                    .detail-total {
                        .pf-c-chart {
                            svg {
                                &:nth-child(1) {
                                    width: 350px !important;
                                }
                            }
                        }
                    }
                }
            }

            .taking-test-area {
                .sub-title {
                    font-size: 16px;
                }

                .question-lst {

                    .content {
                        font-size: 14px;
                    }

                    .options-of-answer {
                        flex-direction: column;

                        .lst-item {
                            .label {
                                font-size: 12px;
                                // &::before {
                                //     height: 10px;
                                //     width: 10px;
                                // }
                            }
                        }
                    }
                }

                .footer {
                    .button-group {
                        flex-direction: column;

                        .number-of-questions-answered {
                            font-size: 10px;
                        }

                        .button {
                            font-size: 12px !important;
                        }

                        .taking-test-area {
                            margin: 10px 0;
                        }


                    }

                }
            }
        }
    }

}

@media (max-width: 400px) {
    .judgement-main {
        margin-top: 40px;
        // padding-top: 40px;

        .intro-part {
            padding-bottom: 50px;

            .text-of-intro {
                margin: 100px 25px 50px 25px;
                margin-top: 60px;

                .title {
                    font-size: 20px;
                }

                .detail {
                    font-size: 12px;
                }

                .button-start {
                    font-size: 10px;
                }
            }

            .image-of-intro {
                display: none !important;
            }
        }

        .criteria-lst {
            margin: 0px 20px;
            margin-top: 20px;

            .lst-item {
                padding: 10px !important;

                .criteria-image {
                    display: none !important;
                    ;
                }

                .criteria-title {
                    font-size: 14px !important;
                }

                .criteria-text {
                    font-size: 11px !important;
                }

                .criteria-btn {
                    font-size: 11px !important;
                }
            }

            .uimpact-options {
                .uimpact-options-title {
                    font-size: 1em !important;
                    text-align: start;
                    line-height: 30px;
                }
            }
        }

        .taking-test {
            margin: 40px 30px;

            .test-body {

                .test-detail {
                    .title {
                        font-size: 20px !important;
                    }

                    .text {
                        line-height: normal;
                        font-size: 16px !important;
                    }

                    .taking-test-area {
                        padding: 10px;
                        margin-top: 10px;

                        .sub-title {
                            font-size: 14px;
                        }

                        .question-lst {
                            .content {
                                line-height: normal;
                                font-size: 14px;
                            }

                            .options-of-answer {
                                flex-direction: column;
                                width: 100%;

                                .lst-item {
                                    margin-right: 10px;

                                    &:hover>.label {
                                        &::before {
                                            border: 5px solid var(--color-primary);
                                            margin-right: 5px;
                                            width: 20px !important;
                                        }
                                    }

                                    .radio-btn {
                                        &:checked+.label {
                                            &::before {
                                                margin-right: 5px;
                                                border: 5px solid var(--color-primary);
                                                background: #EEF2F6;
                                                width: 20px !important;

                                            }
                                        }
                                    }

                                    .label {
                                        font-size: 12px;
                                    }

                                    .label::before {
                                        height: 10px;
                                        width: 10px;
                                    }
                                }
                            }
                        }

                        .footer {
                            flex-direction: column;
                        }
                    }
                }
            }
        }

        .result-answer {
            padding-top: 10px;
            padding-bottom: 2em;

            .result-answers {
                .title-view-test {
                    .title {}

                    .content {}
                }

                .taking-test-area {
                    width: 100%;
                    padding: 1em;
                    margin-top: 0;

                    .sub-title {
                        font-size: 12px;
                    }

                    .question-lst {
                        .content {
                            font-size: 11px;
                        }

                        .options-of-answer {

                            .lst-item {
                                margin: 0;

                                &:hover>.label {
                                    // &::before {
                                    //     border: 5px solid var(--color-primary);
                                    //     margin-right: 5px;
                                    //     width: 20px !important;
                                    // }
                                }

                                .radio-btn {
                                    &:checked+.label {
                                        &::before {
                                            margin-right: 5px;
                                            border: 5px solid var(--color-primary);
                                            background: #EEF2F6;
                                            width: 20px !important;

                                        }
                                    }
                                }

                                .label {
                                    font-size: 8px;
                                }

                                .label::before {
                                    // width: 22px !important;
                                }
                            }
                        }
                    }
                }
            }

            .total-score {
                .total-score-left {
                    .title {
                        font-size: 20px;
                        text-align: center;
                    }

                    .detail-total {
                        .pf-c-chart {
                            svg {
                                &:nth-child(1) {
                                    width: 300px !important;
                                    margin-top: -30px;
                                    margin-bottom: 10px;
                                }

                                g {
                                    &:nth-child(2) {
                                        text {
                                            tspan {
                                                font-size: 9px !important;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .detail-content {
                            h5 {
                                text-align: center;
                                font-size: 16px;
                            }

                            .subtitle {
                                text-align: center;
                                font-size: 12px;
                            }
                        }
                    }
                }

                .total-score-right {
                    .total-score-right-content {
                        .content-score {
                            padding: 1em;

                            .title-content-score {
                                font-size: 10px;
                            }

                            // Tạo ra labels có position absolute để đặt phải của chart
                            .charts {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                flex-direction: column;
                                margin-bottom: 30px;

                                .title-chart {
                                    font-size: 16px;
                                    font-weight: bold;
                                    text-align: center;
                                    margin-bottom: 1em;
                                }

                                .chart {
                                    position: relative;
                                    width: 250px;
                                    height: 250px;

                                    .chart-polar {
                                        position: relative;
                                        z-index: 10;

                                        .logo-chart {
                                            position: absolute;
                                            top: 50%;
                                            left: 50%;
                                            transform: translate(-50%, -50%);
                                            background-color: white;
                                            border-radius: 50%;

                                            img {
                                                width: 30px;
                                            }
                                        }
                                    }

                                    .chart-dough {
                                        width: 270px;
                                        height: 270px;
                                        // z-index: 1;
                                        position: absolute;
                                        top: 50%;
                                        left: 50%;
                                        transform: translate(-50%, -50%);
                                    }

                                    .chart-bar {
                                        width: 270px;
                                        height: 270px;
                                        // z-index: 1;
                                        position: absolute;
                                        top: 50%;
                                        left: 50%;
                                        transform: translate(-50%, -50%);
                                    }
                                }

                                .labels {
                                    display: grid;
                                    grid-template-columns: repeat(2, 1fr);
                                    gap: 10px;

                                    .labels__item {
                                        display: flex;
                                        gap: 5px;
                                        align-items: center;

                                        .labels__item__color {
                                            width: 10px;
                                            height: 10px;
                                            background-color: red;
                                        }

                                        .labels__item__text {
                                            font-size: 8px;
                                            font-weight: 700;
                                        }
                                    }

                                }
                            }

                            .labels {
                                display: grid;
                                grid-template-columns: repeat(2, 1fr);
                                gap: 10px;

                                .labels__item {
                                    display: flex;
                                    gap: 5px;
                                    align-items: center;

                                    .labels__item__color {
                                        width: 10px;
                                        height: 10px;
                                        background-color: red;
                                    }

                                    .labels__item__text {
                                        font-size: 6px;
                                        font-weight: 700;
                                    }
                                }

                            }

                            .label {
                                position: absolute;
                                display: flex;
                                gap: 1em;
                                top: 30px;
                                left: 20%;

                                .label-item {

                                    .label-color {
                                        width: 10px;
                                        height: 10px;
                                    }

                                    .label-name {
                                        font-size: 8px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .taking-test-area {
                .sub-title {
                    font-size: 14px;
                }

                .question-lst {

                    .content {
                        font-size: 12px;
                    }

                    .options-of-answer {
                        flex-direction: column;

                        .lst-item {
                            .label {
                                font-size: 10px;
                                // &::before {
                                //     height: 10px;
                                //     width: 10px;
                                // }
                            }
                        }
                    }
                }

                .footer {
                    .button-group {
                        flex-direction: column;

                        .number-of-questions-answered {
                            font-size: 10px;
                        }

                        .button {
                            font-size: 12px !important;
                        }

                        .taking-test-area {
                            margin: 10px 0;
                        }


                    }

                }
            }

            .turn-back-btn {
                display: flex;
                align-items: center;
                margin-top: 10px;

                .select-option {
                    display: flex;
                    gap: 1em;
                }

                .select-criteria {
                    // max-width: 400px;
                    width: 150px;

                    .ant-select-selector {
                        border-radius: 30px;
                    }
                }

                .home-button {
                    width: 100px;
                }

                .home-button button {
                    background: var(--color-primary);
                    border-radius: 30px;
                    color: #FFFFFF;
                    border: none;
                    font-weight: 600;
                    // font-weight: 700;
                    font-size: 14px;
                    line-height: 20px;
                }


                .home-button button:hover {}
            }
        }
    }

    .pf-c-chart {
        margin: 0px !important;
        padding: 2em 1em;
    }
}

@-webkit-keyframes swing {
    15% {
        -webkit-transform: translateX(5px);
        transform: translateX(5px);
    }

    30% {
        -webkit-transform: translateX(-5px);
        transform: translateX(-5px);
    }

    50% {
        -webkit-transform: translateX(3px);
        transform: translateX(3px);
    }

    65% {
        -webkit-transform: translateX(-3px);
        transform: translateX(-3px);
    }

    80% {
        -webkit-transform: translateX(2px);
        transform: translateX(2px);
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}

@keyframes swing {
    15% {
        -webkit-transform: translateX(5px);
        transform: translateX(5px);
    }

    30% {
        -webkit-transform: translateX(-5px);
        transform: translateX(-5px);
    }

    50% {
        -webkit-transform: translateX(3px);
        transform: translateX(3px);
    }

    65% {
        -webkit-transform: translateX(-3px);
        transform: translateX(-3px);
    }

    80% {
        -webkit-transform: translateX(2px);
        transform: translateX(2px);
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}

.taking-test-save-modal {
    .ant-modal-content {
        .ant-modal-close {}

        .ant-modal-header {
            display: none;

        }


        .ant-modal-body {
            .title {
                font-weight: 700;
                font-size: 30px;
                line-height: 56px;
                text-align: center;
                color: #1E1E1E;
            }

            .body {
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                color: #858585;
                margin-top: 10px;

            }
        }

        .ant-modal-footer {
            border: none;
            display: flex;
            justify-content: space-around;
            align-items: center;
            padding: 1.5em 0;

            .btn-back-test {
                border-radius: 30px;
                color: var(--color-primary);
                background-color: white;
                border: 2px solid var(--color-primary);
                font-weight: 700;
                font-size: 14px;
                line-height: 20px;
            }

            .btn-save-test {
                border-radius: 30px;
                color: white;
                background-color: var(--color-primary);
                border: 2px solid var(--color-primary);
                font-weight: 700;
                font-size: 14px;
                line-height: 20px;
            }
        }

    }
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: var(--color-primary) !important;
    color: #FFFFFF;
}

.ant-select-item {
    font-weight: 500;
    font-size: 11px;
    line-height: 24px;
    color: #696969;
}

.ant-select-item:hover {
    background-color: var(--color-primary) !important;
    color: #FFFFFF;
}

.ant-select-dropdown.ant-select-dropdown-placement-bottomLeft {
    border-radius: 15px;
    padding: 0;
    background: #EEF2F6;

}

.ant-select-selector {
    border-radius: 30px;
    z-index: 3;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    color: #696969;


    .ant-select-selection-search {
        .ant-select-selection-search-input {
            margin-left: 10px;
        }
    }
}

.ant-select-selector:hover {
    outline: none !important;
    border: 1px solid var(--color-primary) !important;
}

.ant-select-selector:focus {
    outline: none !important;
    border: 1px solid var(--color-primary) !important;
    z-index: 3;
    box-shadow: 0 0 0 1px var(--color-primary) !important;
    caret-color: var(--color-primary) !important;

}


.ant-select-arrow {
    color: #4E4E4E !important;
    z-index: 3;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    outline: none !important;
    border: 1px solid var(--color-primary) !important;
    box-shadow: 0 0 0 1px var(--color-primary);
    caret-color: var(--color-primary);
}

.ant-select-clear {
    // user-select: none;
    right: 30px !important;
    top: 13px !important;
    z-index: 3;
}

.pf-c-chart {
    margin: -1em 0 1em 1em !important;
}